export const greenSkillsData = {
  thumbnail: "green-skills-2.png",
  title: "Solar PV, Battery Storage & EV Charging Level 3",
  templateComponentName: "GreenSkillsTemplate",
  about:
    "This Bootcamp Package is designed for existing, qualified electricians looking to up-skill to design, install and maintain new and existing green technologies including Solar PV, Electrical Energy (Battery) Storage and Electric Vehicle Charging Installations.",
  summary: [
    {
      title: "Solar PV",
      description:
        "Aimed at existing electricians, supporting the development of knowledge/understanding of Solar PV installations. Learners will understand the specific requirements for the design and installation of domestic/small commercial solar PV installations.",
    },
    {
      title: "EV Car Charging",
      description:
        "Aimed at existing electricians, supporting the development of knowledge/understanding of electric vehicle charging design and installations. Learners will understand the specific requirements for the design and installation of domestic and small commercial electric vehicle charging installations.",
    },
    {
      title: "Electrical Energy Storage Systems",
      description:
        "This qualification is aimed at existing electricians, supporting the development of knowledge/understanding of energy storage. Learners will understand the specific requirements for design and installation of domestic electrical energy storage systems.",
    },
    {
      title: "Introduction to Domestic Retrofit",
      description:
        "Covers how to assess dwellings for retrofit, identify improvement options, design and specify energy efficiency measures. This course goes hand in hand with green skills and will give learners knowledge of the subject area in order to deal with customer questions about improving energy performance of property.",
    },
  ],
  aims: [
    "Obtain Consumer Code certificate",
    "Join MCS as a certified installer",
    "Offer solar package installation services",
  ],
  courseDetails: [
    {
      dayCount: "5",
      locationType: "Workshop",
      name: "Solar PV",
      description: "Level 3 - (BPEC 0018)",
    },
    {
      dayCount: "1",
      locationType: "Classroom",
      name: "Introduction to Domestic Retrofit",
      description: "Digital Credential – Introduction to Domestic Retrofit",
    },
    {
      dayCount: "2",
      locationType: "Workshop",
      name: "EV Car Charging",
      description: "Level 3 - (City and Guilds 2921-31)",
    },
    {
      dayCount: "2",
      locationType: "Workshop",
      name: "Electrical Energy Storage Systems",
      description: "Level 3 - (BPEC 0059)",
    },
  ],
};

export const airSourceHeatPumpData = {
  thumbnail: "air-source.jpg",
  title: "Air Source Heat Pump (Level 3)",
  templateComponentName: "AirSourceTemplate",
  about:
    "This Bootcamp Package is designed for existing heating engineers to be able to install air source heat pump technologies. This course also includes a minor electrical works module to be able to carry out basic electrical works and introduction to domestic retrofit to give overview of the retrofit options available for homeowners and a water regulation (bylaws) qualification, which is a pre-requisite to this heat pump qualification.",
  summary: [
    {
      title: "Minor Electrical Works (Part 1)",
      description:
        "Allows heat pump installers to conduct basic minor electrical works, e.g connecting a feed to the equipment. Includes different circuits and scenario based activities. Learners also test their own installations to ensure they are safe.",
    },
    {
      title: "Minor Electrical Works (Part 2)",
      description:
        "eLearning introduces fundamental requirements for making additions or alterations to existing electrical circuits. Explores specific requirements for IET Wiring Regulations and Building Regulations.",
    },
    {
      title: "Introduction to domestic retrofit",
      description:
        "Covers how to assess dwellings for retrofit (heat pumps), identify improvement options, design and specify energy efficiency measures. This knowledge goes hand-in-hand with heat pump installations to ensure they have capacity to work efficiently.",
    },
    {
      title: "Water Regulations",
      description:
        "Gives an understanding of water regulations/water bylaws - a pre-requisite for anyone undertaking a heat pump qualification.",
    },
    {
      title: "Heat Pumps",
      description:
        "Aimed at experienced heating installers who wish to expand their knowledge/skills to include fitting heat pumps in domestic and small commercial properties. Successful learners will also meet entry requirements for the Micro-generation Certification Scheme (MCS).",
    },
  ],
  aims: [
    "Obtain HIES Consumer code certificate",
    "Join MCS as a certified installer",
    "Offer heat pump installation services",
  ],
  courseDetails: [
    {
      dayCount: "5",
      locationType: "Domestic Workshop",
      name: "Minor Electrical Works",
      description: "Digital Credential – Minor Electrical Works",
    },
    {
      dayCount: "1",
      locationType: "Classroom",
      name: "Introduction to Domestic Retrofit",
      description: "Digital Credential – Introduction to Domestic Retrofit",
    },
    {
      dayCount: "1",
      locationType: "Classroom",
      name: "Water Regulations",
      description: "LCL Awards Water Regulations",
    },
    {
      dayCount: "3",
      locationType: "Heat Pump Workshop",
      name: "Heat Pumps",
      description:
        "LCL Award Level 3 Award in the Installation and Maintenance of Heat Pump Systems (non-refrigerant circuits)1",
    },
  ],
};
