import ProjectService from "@/core/services/project.service";
import {
  AttachmentCategory,
  CreateProject,
  ProjectData,
  ProjectDataSelectedProperties,
  UpdateProject,
} from "@/core/models/project";
import { Commit } from "vuex";
import TradeEstimateService from "@/core/services/trade-estimate.service";
import ProjectEstimationService from "@/core/services/project-estimation.service";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import historicalProjectService from "@/core/services/historical-project.service";
import projectService from "@/core/services/project.service";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import raiseIssueService from "@/core/services/raise-issue.service";

export default {
  async attachmentCategory(
    { state, commit }: any,
    payload: AttachmentCategory
  ) {
    const { label, value } = payload;
    return ProjectService.attachmentCategory(label, value).then((response) => {
      return response;
    });
  },
  async createProject({ state, commit }: any, payload: CreateProject) {
    return ProjectService.createProject(payload).then((response) => {
      return response;
    });
  },
  async createRaiseProject({ state, commit }: any, payload: CreateProject) {
    return ProjectService.createRaiseProject(payload).then((response) => {
      return response;
    });
  },
  projectDetails({ commit }: { commit: Commit }, payload: any) {
    commit("setProjectDetails", payload);
  },
  resetProjectDetails(
    { commit, getters }: { commit: Commit; getters: any },
    payload: any
  ) {
    commit("resetProjectDetails", getters.defaultProjectDetails);
  },
  setProjectAttachments({ commit }: { commit: Commit }, payload: any) {
    commit("setProjectAttachments", payload);
  },
  setProjectSpecialisms({ commit }: { commit: Commit }, payload: any) {
    commit("setProjectSpecialisms", payload);
  },
  removeProjectSpecialism({ commit }: { commit: Commit }, payload: any) {
    commit("removeProjectSpecialism", payload);
  },
  addProjectSpecialism({ commit }: { commit: Commit }, payload: any) {
    commit("addProjectSpecialism", payload);
  },
  setInvitedContractors({ commit }: { commit: Commit }, payload: any) {
    commit("setInvitedContractors", payload);
  },
  async getProjectData({ commit }: { commit: Commit }, payload: ProjectData) {
    const { projectRefId } = payload;
    return ProjectService.getProjectData(projectRefId).then((response) => {
      return response;
    });
  },
  async getProjectDataProperties(
    { commit }: { commit: Commit },
    payload: ProjectData
  ) {
    const { projectRefId } = payload;
    return ProjectService.getProjectDataProperties(projectRefId).then(
      (response) => {
        return response;
      }
    );
  },
  async getProjectDataSelectedProperties(
    { commit }: { commit: Commit },
    payload: ProjectDataSelectedProperties
  ) {
    const { projectRefId, propertyIds } = payload;
    return ProjectService.getProjectDataSelectedProperties(
      projectRefId,
      propertyIds
    ).then((response) => {
      return response;
    });
  },
  async updateProject(
    { state, commit }: any,
    payload: { data: UpdateProject; projectId: number }
  ) {
    return ProjectService.updateProject(payload.data, payload.projectId).then(
      (response) => {
        return response;
      }
    );
  },
  async updateSubSpecialismCategory(
    { state, commit }: any,
    payload: { data: UpdateProject; projectId: number }
  ) {
    return ProjectService.updateSubSpecialismCategory(
      payload.data,
      payload.projectId
    ).then((response) => {
      return response;
    });
  },
  async updateSpecialism(
    { state, commit }: any,
    payload: { specialism: any; projectId: number }
  ) {
    return ProjectService.updateSpecialism(
      payload.specialism,
      payload.projectId
    ).then((response) => {
      return response;
    });
  },
  async getFilterProjectData({ commit }: { commit: Commit }, params: any) {
    if (!params) return;
    return ProjectService.getFilterProjectData(params).then((response) => {
      return response;
    });
  },
  async setProjectRedirectTabName(
    { commit }: { commit: Commit },
    TabName: string | null
  ) {
    commit("setProjectRedirectTabName", TabName);
  },
  async getProjectCategoryList(
    { commit }: { commit: Commit },
    projectId: number
  ) {
    return ProjectService.getProjectCategoryList(projectId);
  },

  async getProjectEstimate({ commit }: { commit: Commit }, projectId: number) {
    return ProjectEstimationService.getProjectEstimateByProjectId(
      projectId,
      true
    );
  },
  async getProjectEstimateWithEscrow(
    { commit }: { commit: Commit },
    projectEstimateId: number
  ) {
    try {
      if (!projectEstimateId) return null;
      const response =
        await tradeEstimateService.getProjectEstimateWithEscrowStatus(
          projectEstimateId
        );

      commit("setProjectEstimateDataWithEscrowStatus", response);
      return response;
    } catch (error) {
      return null;
    }
  },

  setHistoricalProjectForm({ commit }: { commit: Commit }, value: any) {
    commit("setHistoricalProjectForm", value);
  },

  setHistoricalProjectFormSpecialism(
    { commit }: { commit: Commit },
    value: any
  ) {
    commit("setHistoricalProjectFormSpecialism", value);
  },

  async setProjectEstimateEscrowBalance(
    { commit }: { commit: Commit },
    projectId: any
  ) {
    try {
      const response = (await tradeEstimateService.getEscrowAccountBalance(
        projectId
      )) as any;
      if (response) commit("setProjectEstimateEscrowBalance", response.amount);
    } catch (error) {
      console.log(error);
    }
  },

  async updateRaiseProjectStatus(
    { state, commit }: any,
    payload: {
      projectId: number;
      data: {
        status: number;
      };
    }
  ) {
    return ProjectService.updateRaiseProjectStatus(
      payload.projectId,
      payload.data
    ).then((response) => {
      return response;
    });
  },

  async updateHistoricalProject(
    { state, commit }: any,
    payload: { data: UpdateProject; projectId: number }
  ) {
    return historicalProjectService
      .updateHistoricalJobDetail(payload.data, payload.projectId)
      .then((response) => {
        return response;
      });
  },
  async updateHistoricalProjectStatus(
    { state, commit }: any,
    payload: {
      projectId: number;
      data: {
        historicalProjectStatus: number;
        projectEstimateId: number;
      };
    }
  ) {
    return historicalProjectService
      .updateHistoricalJobStatus(payload.projectId, payload.data)
      .then((response) => {
        return response;
      });
  },
  async getRecommendationForJobDescription(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      const { prompt } = payload;
      return ProjectService.getRecommendationForJobDescription(prompt).then(
        (response) => {
          return response;
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
  async getRecommendationForJobTitle(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      const { prompt } = payload;
      return ProjectService.getRecommendationForJobTitle(prompt).then(
        (response) => {
          return response;
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
  async getSpecialismRecommendation(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      const { description } = payload;
      return ProjectService.getSpecialismRecommendation(description).then(
        (response) => {
          return response;
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
  async getNewAddedSubCategoryForProject(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      return ProjectService.getNewAddedSubCategoryForProject(payload).then(
        (response) => {
          return response;
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
  async getVideoConsultationRoomUrl(
    { commit }: { commit: Commit },
    payload: any
  ) {
    console.log("payload", payload);
    try {
      const { roomTitle, type } = payload;
      return ProjectService.getVideoConsultationRoomUrl({
        ...payload,
      }).then((response) => {
        return response;
      });
    } catch (error: any) {
      console.log(error);
      return error?.response;
    }
  },

  async fetchAllRequestedProjects(
    { commit }: { commit: Commit },
    payload: any
  ) {
    return ProjectService.getAllRequestedProjects(payload)
      .then((response) => {
        commit("setProjectRequestedList", response?.data);
        return response;
      })
      .catch((error) => {
        console.log("error", error);
      });
  },

  resetAllRequestedProjects({ commit }: { commit: Commit }, payload: any) {
    commit("setProjectRequestedList", payload);
  },
  async setProjectStatusCounts({ commit }: { commit: Commit }, payload: any) {
    try {
      const projectListing: any = await projectService.getAllProjects();
      const statusCounts = projectListing.data.reduce(
        (obj: any, project: any) => {
          const statusId = project.projectJobStatusId;
          obj[statusId] = (obj[statusId] || 0) + 1;
          return obj;
        },
        {}
      );
      statusCounts["all"] = projectListing?.totalCount || 0;
      const draftProjectData: any = await projectService.getAllProjects({
        projectJobStatus: ProjectJobStatusEnum.DRAFT_PROJECT,
      });
      statusCounts[ProjectJobStatusEnum.DRAFT_PROJECT] =
        draftProjectData?.totalCount || 0;

      const allRaiseIssue: any = await raiseIssueService.getAllIssue("", "");
      statusCounts["issue"] = allRaiseIssue?.length || 0;
      commit("setProjectStatusCountsList", statusCounts);
    } catch (error) {
      console.log("set project overview data");
    }
  },
};
