import _ from "lodash";

const projectDetails = {
  projectType: null,
  projectSubType: null,
  projectTitle: null,
  projectDescription: null,
  projectStartDate: null,
  projectEndDate: null,
};
export default {
  projectDetails,
  defaultProjectDetails: _.cloneDeep(projectDetails),

  draftProjectList: [],
  selectedPropertiesList: [],
  propertyListState: null,
  projectRedirectTabName: null,
  projectEstimateWithEscrow: null,

  projectSpecialisms: [],
  projectAttachments: [],
  invitedContractors: [],

  projectRequestedList: [],
  // temporary form state
  historicalProjectForm: {
    projectType: null,
    specialisms: [],
    name: "",
    description: "",
    property: null,
    attachments: null,
    invitedContractors: [],

    projectEstimate: {
      estimateStartDate: null,
      estimates: [],
    },
  },
  projectEstimateEscrowBalance: 0,
  projectStatusCountsList: {},
};
