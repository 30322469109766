import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95bb16bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_ProjectListComponent = _resolveComponent("ProjectListComponent")!
  const _component_ProjectListEmptyState = _resolveComponent("ProjectListEmptyState")!
  const _component_PropertyListComponent = _resolveComponent("PropertyListComponent")!
  const _component_PageRestrictionNoticeModal = _resolveComponent("PageRestrictionNoticeModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ($setup.isLoading)
            ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!$setup.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          ($setup.getProjectCount)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["post_project_wrapper md:tw-box-border", { 'md:!tw-pb-16': $setup.isShowProjectListing }])
              }, [
                (!$setup.isShowPropertyList)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_v_btn, {
                        class: "button button-orange purple_btn new_project_button",
                        size: "small",
                        onClick: $setup.onClickTogglePropertySelect
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-plus-circle",
                            class: "mr-2"
                          }),
                          _createTextVNode(" New Project ")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true),
                ($setup.isShowProjectListing)
                  ? (_openBlock(), _createBlock(_component_ProjectListComponent, { key: 1 }))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          (!$setup.getProjectCount && !$setup.isShowPropertyList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_ProjectListEmptyState, {
                  "allow-button": true,
                  headingText: "active",
                  onOpenNewProject: $setup.onClickTogglePropertySelect
                }, null, 8, ["onOpenNewProject"])
              ]))
            : _createCommentVNode("", true),
          ($setup.isShowPropertyList)
            ? (_openBlock(), _createBlock(_component_PropertyListComponent, {
                key: 2,
                onOnClickBackButton: $setup.onClickBackButton
              }, null, 8, ["onOnClickBackButton"]))
            : _createCommentVNode("", true),
          ($setup.isShowWorkstationRestrictionModal)
            ? (_openBlock(), _createBlock(_component_PageRestrictionNoticeModal, {
                key: 3,
                onOnClose: $setup.toggleRestrictionModal
              }, null, 8, ["onOnClose"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}