<template>
  <div
    class="main_block"
    id="main_block_container"
    ref="customBorderLayout"
    :class="{
      'flex-col mobile_main_block': isMobileView,
    }"
  >
    <!-- Your SidebarView component here -->
    <v-menu
      v-if="isMobileView"
      transition="slide-y-reverse-transition"
      class="slider-menu-active"
      activator="#menu-activator"
      height="700"
      width="300"
    >
      <SidebarView />
    </v-menu>
    <SidebarView
      v-if="
        !isMobileView && !isWorkstationLoading && !isUserWorkstationIsDefault
      "
      @expandNavBar="onExpandNavBar"
    />
    <div
      class="main_block_right"
      id="mainRightBlock"
      :class="{
        'rxl:!tw-w-full': isUserWorkstationIsDefault || isWorkstationLoading,
        '!tw-bg-white !bg-none': isDashboard,
      }"
    >
      <div class="header">
        <!-- Your HeaderPage component here -->
        <HeaderPage />
      </div>
      <div
        class="content md:!tw-h-[calc(100%-141px)]"
        :class="{
          'is-show-chat-layout': shouldShowChatLayout,
          'is-default-layout': isUserWorkstationIsDefault,
          '!tw-bg-white !bg-none': isDashboard,
        }"
        id="main_layout_content"
      >
        <!-- Your slot content here -->
        <slot />
      </div>
    </div>
    <v-sheet
      class="bottom-bar"
      v-if="isMobileView && !isUserWorkstationIsDefault"
    >
      <v-tabs
        bg-color="#0C0F4A"
        color="#FFA500"
        hide-slider
        stacked
        show-arrows
      >
        <v-tab
          v-for="module in mobileViewModules"
          :key="module.name"
          :value="module.name"
          :id="module.name === 'Menu' ? 'menu-activator' : ''"
          :disabled="checkIsModuleAllowedUser(module.name)"
          @click="onClickModule(module.route, module.name)"
        >
          <img
            v-if="module.icon"
            :src="
              require('@/assets/icons/sidebar-icons/' + module.icon + '.svg')
            "
            alt="icon"
          />
          <div>{{ module.name }}</div>
        </v-tab>
      </v-tabs>
    </v-sheet>
    <!-- <GeminiFlashComponent /> -->
    <GlobalChatsComponent
      v-if="
        activeUserWorkstation?.name !== 'Default' && isChatShow && isOpenChat
      "
      @on-close="toggleOpenChat"
    />

    <!-- <ExploreDummyDataNoticeModal
      v-if="dummyDataNoticeModal && isPersonalAccountWorkStation"
      @on-close="onCloseDummyDataNoticeModal"
    />-->
    <div
      class="chatbot_wrapper md:!tw-hidden"
      v-if="
        !isOpenChat && isChatShow && activeUserWorkstation?.name !== 'Default'
      "
      @click="toggleOpenChat"
    >
      <span class="chat-count" v-if="totalUnreadMessageCount > 0">
        {{ totalUnreadMessageCount }}
      </span>
      <div class="chatbot__box">
        <img src="../../../assets/icons/chat-icon.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";
import HeaderPage from "@/core/components/HeaderPage.vue";
import SidebarView from "@/core/components/SidebarView.vue";
import { computed, onMounted, watch, ref, provide } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import {
  BusinessDetailTypeEnum,
  RolesEnum,
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { useRouter, useRoute } from "vue-router";
import { WORKSTATION } from "@/store/modules/workstation";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import insurancesService from "@/core/services/insurances.service";
import GlobalChatsComponent from "../global/GlobalChatsComponent.vue";
// import GeminiFlashComponent from "../global/GeminiFlashComponent.vue";
import { EMERGENCY_OUT_OF_HOURS } from "@/modules/emergency-out-of-hours/routes";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
// import ExploreDummyDataNoticeModal from "@/core/components/modals/ExploreDummyDataNoticeModal.vue";
import { CHATMESSAGE } from "@/store/modules/chat-message";
export default {
  name: "GeneralLayout",
  components: {
    HeaderPage,
    SidebarView,
    GlobalChatsComponent,
    // GeminiFlashComponent,
    // ExploreDummyDataNoticeModal,
  },
  setup() {
    const vuetify = useDisplay();
    const getNetworkDirectMessage = computed(
      () => store.getters[`${CHATMESSAGE}/getNetworkDirectMessage`]
    );
    const getProjectChatRequests = computed(
      () => store.getters[`${CHATMESSAGE}/getProjectChatRequests`]
    );
    const store = useStore();

    const router = useRouter();
    const route = useRoute();
    const isChatShow = computed(() => {
      if (route.path === "/chat") return false;
      else return true;
    });
    const isDashboard = computed(() => {
      return route.path === "/dashboard";
    });
    const isOpenChat = ref(true);
    const toggleOpenChat = () => {
      isOpenChat.value = !isOpenChat.value;
    };
    const preOpenSideBarTabsInTradePerson = ref(["Home", "Passport", "Menu"]);
    const preOpenSideBarTabsInPropertyOwner = ref(["Home"]);
    const dummyDataNoticeModal = ref(false);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const customBorderLayout = ref(null);
    const userWorkStationStatus = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );
    const allMessagesRooms = computed(
      () => store.getters[`${CHATMESSAGE}/getChatMessagesRooms`]
    );
    const getChatMessagesRooms = computed(
      () => store.getters[`${CHATMESSAGE}/filterMessagesRooms`]
    );
    const groupRooms = computed(() => {
      return getChatMessagesRooms.value.filter(
        (room) => room.isGroup && !room.project
      );
    });

    const groupsUnreadMsgCount = computed(() => {
      let totalUnreadCount = 0;

      groupRooms?.value?.forEach((grp) => {
        let groupHasUnreadMessages = false;
        grp?.userMessageStatus?.forEach((message) => {
          const unreadStatuses = message?.statuses?.filter((status) => {
            return (
              status.state === "unread" &&
              status.user?.id === user.value.id &&
              status.userWorkstation?.id === activeUserWorkstation?.value.id
            );
          });

          if (unreadStatuses?.length > 0) {
            groupHasUnreadMessages = true;
          }
        });

        if (groupHasUnreadMessages) {
          totalUnreadCount += 1;
        }
      });

      return totalUnreadCount;
    });

    const totalUnreadMessageCount = computed(() => {
      let countMsg = 0;
      allMessagesRooms.value.forEach((msg) => {
        if (msg?.unreadMessagesCount) {
          countMsg++;
        }
      });
      return countMsg + (groupsUnreadMsgCount.value > 0 ? 1 : 0);
    });
    watch(
      () => getNetworkDirectMessage.value,
      (value) => {
        if (value && getNetworkDirectMessage.value) {
          isOpenChat.value = true;
        }
      },
      { immediate: true, deep: true }
    );
    watch(
      () => getProjectChatRequests.value,
      (value) => {
        if (value && getProjectChatRequests.value) {
          isOpenChat.value = true;
        }
      },
      { immediate: true, deep: true }
    );

    const shouldShowChatLayout = computed(() => {
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        return (
          activeUserWorkstation.value?.verificationStatus ===
          UserVerificationStatusEnum.VERIFIED
        );
      } else {
        return activeUserWorkstation.value?.name !== "Default";
      }
    });

    const isWorkStationVerified = computed(() => {
      return (
        userWorkStationStatus.value === UserVerificationStatusEnum.VERIFIED
      );
    });

    const isPersonalAccountWorkStation = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name ===
        "Default"
    );

    const isUserWorkstationIsDefault = computed(
      () =>
        activeUserWorkstation.value?.name === "Default" &&
        activeUserWorkstation.value?.status === "active"
    );

    const isWorkstationLoading = computed(
      () => store.getters[`${WORKSTATION}/loadingWorkstation`]
    );
    const onOpenDummyDataNoticeModal = () => {
      dummyDataNoticeModal.value = true;
    };

    const onCloseDummyDataNoticeModal = () => {
      dummyDataNoticeModal.value = false;
    };

    provide("customBorderLayout", customBorderLayout);
    const onExpandNavBar = (value) => {
      if (value) {
        document.getElementById(
          "mainRightBlock"
        ).style.width = `calc(100% - ${100}px)`;
      } else {
        document.getElementById(
          "mainRightBlock"
        ).style.width = `calc(100% - ${300}px)`;
      }
    };
    const isMobileView = computed(() => vuetify.mdAndDown.value);
    watch(isMobileView, (newValue) => {
      document.getElementById("mainRightBlock").style.width = newValue
        ? "100%"
        : `calc(100% - ${300}px)`;
    });

    watch(route, (newValue, oldValue) => {
      // console.log(newValue, "newValue");
      if (
        isPersonalAccountWorkStation.value &&
        newValue.name !== DASHBOARD_ROUTE &&
        newValue.name !== "courseDetails"
      ) {
        onOpenDummyDataNoticeModal();
      }
    });

    const mobileViewModules = computed(() => {
      if (user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER) {
        return [
          {
            name: "Home",
            icon: "dashboard",
            route: "dashboard",
          },
          {
            name: "Properties",
            icon: "property",
            route: "properties",
          },
          {
            name: "Out Of Hours",
            icon: "clock_circle",
            route: EMERGENCY_OUT_OF_HOURS,
          },
          { name: "Projects", icon: "projects", route: "project" },
          // { name: "Chat", icon: "chat", route: "chat" },
          { name: "Menu", icon: "menu" },
        ];
      } else if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        return [
          { name: "Home", icon: "dashboard", route: "dashboard" },
          {
            name: "Trade Passport",
            icon: "trade-passport",
            route: "trade-passport",
          },
          {
            name: "Jobs",
            icon: "jobs",
            route: "jobs",
          },
          { name: "Courses", icon: "courses", route: "courses" },
          // { name: "Chat", icon: "chat", route: "/chat" },

          { name: "Menu", icon: "menu" },
        ];
      } else {
        return [];
      }
    });
    const isInsurenceExpired = async (id) => {
      const responce = await insurancesService.isInsuranceExpired(id);

      // userInsurenceExperiedStatus.value = true;
      store.commit(
        `${INSURANCE_STORE}/setInsuranceExpired`,
        responce.isFreezed
      );
    };
    const onClickModule = (path, name) => {
      if (isWorkStationVerified.value) {
        isInsurenceExpired(user.value.id);
      }
      checkIsModuleAllowedUser(name);
      if (path) {
        router.push({ name: path });
      }
    };
    watch(isWorkStationVerified, (newVal, oldVal) => {
      if (newVal) {
        if (isWorkStationVerified.value) {
          isInsurenceExpired(user.value.id);
        }
      }
    });

    onMounted(async () => {
      document.getElementById("mainRightBlock").style.width = isMobileView.value
        ? "100%"
        : `calc(100% - ${300}px)`;
      try {
        if (
          !user.value?.userBusinessDetails &&
          user.value?.role.id === RolesEnum.TRADESPERSON
        ) {
          const data = {
            type: BusinessDetailTypeEnum.SOLETRADER,
            businessName: user.value.lastName,
            userVerificationStatus:
              UserVerificationStatusEnum.PENDING_VERIFICATION,
          };

          await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
            userId: user.value.id,
            formData: data,
          });

          await store.dispatch(`${USER_STORE}/initializeUserState`);
          await store.dispatch(
            `${CHATMESSAGE}/getUserRoomMessages`,
            activeUserWorkstation.value?.id
          );
        }
      } catch (error) {
        console.log();
      }
    });
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const userInsurenceExperiedStatus = computed(
      () => store.getters[`${INSURANCE_STORE}/getIsInsuranceExpired`]
    );

    const checkIsModuleAllowedUser = (value) => {
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        if (!preOpenSideBarTabsInTradePerson.value.includes(value)) {
          if (activeUserWorkstation.value?.name === "Default") {
            return false;
          }
          if (userInsurenceExperiedStatus.value) {
            if (
              value === "Trade Passport" ||
              value === "Calendar" ||
              value === "Documents" ||
              value === "Trade Network"
            ) {
              return false;
            }
            return true;
          }
          if (
            activeUserWorkstation.value?.verificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
          ) {
            return true;
          }
          if (
            value !== "Trade Passport" &&
            value !== "Calendar" &&
            value !== "Documents" &&
            value !== "Trade Network" &&
            activeUserWorkstation.value?.verificationStatus !==
              UserVerificationStatusEnum.VERIFIED
          ) {
            return true;
          } else {
            return false;
          }
        }
        if (
          (value === "Trade Passport" ||
            value === "Calendar" ||
            value === "Documents" ||
            value === "Trade Network") &&
          activeUserWorkstation.value?.verificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
        ) {
          return true;
        }
        return false;
      } else {
        if (!preOpenSideBarTabsInPropertyOwner.value.includes(value)) {
          if (activeUserWorkstation.value?.name === "Default") {
            return false;
          }
          return false;
        }
      }
    };

    watch(
      () => activeUserWorkstation.value?.id,
      async (newValue, oldValue) => {
        if (newValue !== oldValue && activeUserWorkstation.value?.id) {
          isOpenChat.value = true;
        }
      },
      { immediate: true, deep: true }
    );

    return {
      onExpandNavBar,
      mobileViewModules,
      isMobileView,
      onClickModule,
      customBorderLayout,
      checkIsModuleAllowedUser,
      activeUserWorkstation,
      UserVerificationStatusEnum,
      user,
      shouldShowChatLayout,

      dummyDataNoticeModal,
      onCloseDummyDataNoticeModal,
      onOpenDummyDataNoticeModal,
      isPersonalAccountWorkStation,
      isUserWorkstationIsDefault,
      isChatShow,
      isWorkstationLoading,
      isOpenChat,
      toggleOpenChat,
      totalUnreadMessageCount,
      isDashboard,
    };
  },
};
</script>
<style lang="scss" scoped>
.custom__border:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100vh;
  border: 5px solid rgb(255, 165, 0);
  z-index: 1;
  pointer-events: none;
}
.main_block {
  display: flex;
  height: 100dvh;
  flex-direction: column;
}

/* Header styling */
.header {
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Content styling */

.chat-logo {
  position: fixed;
  right: 50px;
  bottom: 40px;
  max-width: 4%;
}

.main_block_right {
  overflow: hidden;
  width: calc(100% - 300px);
  // width: 100%;
  margin-left: auto;
  transition: all 0.3s;
  overflow-y: hidden !important;
  overflow-x: hidden;
  @include respond(lg) {
    width: 100%;
  }

  .content {
    flex: 1;
    width: 100%;
    // background-image: linear-gradient(111.5deg, #f5faff 0%, #d3e9ff 100%);
    text-align: center;
    padding: 24px 50px 0;
    overflow: auto;
    position: relative;
    border-top-left-radius: 37px;
    height: calc(100vh - 90px);
    @include respond(s720) {
      padding: 24px;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    @include respond(xlg) {
      padding: 24px 30px 0px;
      height: calc(100% - 150px) !important;
    }
    @include respond(s720) {
      padding: 24px 24px 0px !important;
      // max-height: 740px !important;
      border-top-left-radius: 0;
      height: calc(100% - 150px) !important;
    }
    @include respond(sm) {
      padding: 15px 15px 0px !important;
      height: calc(100% - 140px) !important;
    }
    @include respond(s414) {
      height: calc(100% - 130px) !important;
    }
    @include respond(xs) {
      // height: calc(100% - 181px) !important;
      height: calc(100% - 140px) !important;
    }
  }
  .is-default-layout {
    @include respond(xlg) {
      height: calc(100% - 84px) !important;
    }
    @include respond(xs) {
      height: calc(100% - 133px) !important;
    }
    @include respond(s414) {
      height: calc(100% - 81px) !important;
    }
  }
  .is-show-chat-layout {
    @include respond(s414) {
      height: calc(100% - 189px) !important;
    }
    @include respond(xs) {
      height: calc(100% - 139px) !important;
    }
  }
}
.chatbot_wrapper {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
  .chatbot__box {
    width: 45px;
    height: 45px;
    background-color: rgba($orange, 1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      filter: invert(100%) sepia(4%) saturate(7487%) hue-rotate(262deg)
        brightness(108%) contrast(102%);
      width: 24px;
      height: 24px;
    }
  }
}
.chat-count {
  font-size: 12px;
  background-color: #264fd5;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: 0;
}
</style>
