<template>
  <CollapsibleWidgetSlot title="Projects">
    <template #content>
      <section class="tw-flex tw-w-full tw-flex-col">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="tw-flex tw-w-full tw-justify-between tw-items-center tw-border-t-solid-custom tw-py-3 tw-px-4 tw-box-border"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <component :is="item.icon" />
            <Text variant="span">{{ item.title }}</Text>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-gap-8">
            <Text variant="h5">{{ item.count }}</Text>
            <Button
              class="!tw-w-auto !tw-h-[28px] !tw-min-h-[28px] !tw-max-h-[28px] !tw-px-2"
              :class="{ 'tw-invisible': !item?.hasButton }"
              label="Raise Issue"
              variant="secondary"
              @click="redirectToRaiseIssue"
              :isCapitalize="true"
            ></Button>
          </div>
        </div>
      </section>
    </template>
  </CollapsibleWidgetSlot>
</template>
<script setup>
import { reactive } from "vue";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import InProgressIcon from "@/core/components/icons/InProgressIcon.vue";
import IssueRaisedIcon from "@/core/components/icons/IssueRaisedIcon.vue";
import EstimateReceivedIcon from "@/core/components/icons/EstimateReceivedIcon.vue";
import ProjectPostedIcon from "@/core/components/icons/ProjectPostedIcon.vue";
import NotStartedIcon from "@/core/components/icons/NotStartedIcon.vue";
import DraftJobsIcon from "@/core/components/icons/DraftJobsIcon.vue";
import { ISSUE_ROUTE } from "@/modules/issues/routes";
import { useRouter } from "vue-router";

const inProgress = InProgressIcon;
const estimateReceived = EstimateReceivedIcon;
const notStarted = NotStartedIcon;
const draftJobs = DraftJobsIcon;
const issueRaised = IssueRaisedIcon;
const ProjectPosted = ProjectPostedIcon;

const router = useRouter();

const redirectToRaiseIssue = () => {
  router.push({ name: ISSUE_ROUTE });
};

const items = reactive([
  {
    icon: inProgress,
    title: "In progress",
    count: "0",
  },
  {
    icon: issueRaised,
    title: "Issues Raised",
    count: "0",
    hasButton: true,
  },
  {
    icon: estimateReceived,
    title: "Estimates received",
    count: "0",
  },
  {
    icon: notStarted,
    title: "Not Started",
    count: "0",
  },
  {
    icon: draftJobs,
    title: "Draft Projects",
    count: "0",
  },
  {
    icon: ProjectPosted,
    title: "Project Posted",
    count: "0",
    noBorder: true,
  },
]);
</script>
<style lang="scss" scoped></style>
