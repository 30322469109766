<template>
  <CollapsibleWidgetSlot title="Property Performance">
    <template #content>
      <section
        class="tw-flex tw-w-full tw-h-full tw-border-t-solid-custom tw-p-4 tw-box-border tw-gap-4 lg:tw-flex-col"
      >
        <div
          class="tw-max-w-[404px] tw-min-w-[404px] tw-h-full lg:tw-w-full lg:tw-min-w-full lg:tw-max-w-full"
        >
          <PropertyRatingCard
            @onClickCategoryMap="onClickCategoryMap"
            :hideAction="true"
          />
        </div>
        <div
          class="tw-h-full tw-flex-col tw-flex tw-bg-[#F1F6FB] tw-rounded-lg tw-gap-4 tw-pt-8 tw-pb-4 tw-px-4 tw-box-border tw-w-[calc(100%-420px)] tw-w-max-w-full lg:tw-w-full"
        >
          <div class="tw-flex tw-items-center tw-gap-4">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              class="input_field"
              placeholder="Search"
              v-model="filters.keywords"
              bg-color="white"
              variant="outlined"
              @input="findKeywords"
            ></v-text-field>

            <v-select
              bg-color="white"
              class="input_field"
              density="compact"
              variant="outlined"
              :menu-props="{ contentClass: 'custom__text__selector' }"
              placeholder="Type"
              :items="categoryOptions"
              v-model="selectedFilter"
              item-title="text"
              item-value="value"
              @update:modelValue="handleCategorySelection(selectedFilter)"
            ></v-select>
          </div>
          <div
            class="tw-w-full tw-h-full tw-flex tw-flex-col tw-rounded-t-lg tw-bg-white lg:tw-hidden"
          >
            <div
              class="tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-items-center tw-justify-start"
            >
              <Text variant="h5">{{ selectedFilter }}</Text>
            </div>
            <PropertyPerformanceTable @on-edit="onOpenModal" />
          </div>
          <div class="tw-hidden lg:tw-block tw-full tw-text-left">
            <h5
              class="tw-p-4 tw-bg-white tw-rounded-t-lg tw-border-b tw-border-solid tw-border-[#0000001a] tw-border-0 tw-text-sm tw-font-bold tw-leading-6 tw-text-[#0C0F4A]"
            >
              {{ selectedFilter }}
            </h5>
            <div class="lg:tw-flex tw-gap-3 tw-items-center tw-flex-col">
              <PropertyPerformanceTable @on-edit="onOpenModal" />
            </div>
          </div>
        </div>
      </section>
    </template>
  </CollapsibleWidgetSlot>

  <!-- MODALS -->
  <EditEnergyEfficiencyRatingModal
    v-if="editEnergyEfficiencyModal"
    :epcDataListByCategoryId="epcDataListByCategoryId"
    @on-close="onCloseModal"
  />
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Text from "@/core/components/ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import PropertyRatingCard from "@/modules/properties/components/Cards/PropertyRatingCard.vue";
import PropertyPerformanceTable from "@/modules/properties/components/Tables/PropertyPerformanceTable.vue";
import EditEnergyEfficiencyRatingModal from "@/modules/properties/components/Modals/EditEnergyEfficiencyRatingModal.vue";
import { PROPERTY_STORE } from "@/store/modules/property";

const store = useStore();
const route = useRoute();

const filters = reactive({
  keywords: "",
});

const editEnergyEfficiencyModal = ref(false);
const epcDataListByCategoryId = ref();
const selectedFilter = ref("Energy");
const categoryOptions = ref([
  { text: "Energy", value: "energy" },
  { text: "Heating", value: "heating" },
  { text: "Lighting", value: "lighting" },
  { text: "Ventilation", value: "ventilation" },
  { text: "Windows", value: "windows" },
  { text: "Roof", value: "roof" },
  { text: "Walls", value: "walls" },
  { text: "Floors", value: "floors" },
]);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const propertyId = computed(() => route?.params?.propertyId);

const findKeywords = async () => {
  try {
    let params = { search: filters.keywords };

    params = Object.fromEntries(
      Object.entries(params).filter((value) =>
        value[1] === 0 ? true : value[1]
      )
    );
    await store.dispatch(`${PROPERTY_STORE}/fetchEPCDataList`, {
      propertyId: propertyId.value,
      params: params,
    });
  } catch (error) {
    console.log(error, "error in fetching details");
  }
};

const handleCategorySelection = async (selectedFilter) => {
  try {
    let params = { category: selectedFilter };

    params = Object.fromEntries(
      Object.entries(params).filter((value) =>
        value[1] === 0 ? true : value[1]
      )
    );
    await store.dispatch(`${PROPERTY_STORE}/fetchEPCDataList`, {
      propertyId: propertyId.value,
      params: params,
    });
  } catch (error) {
    console.log(error, "error in fetching details");
  }
};

const onOpenModal = (value) => {
  editEnergyEfficiencyModal.value = true;
  epcDataListByCategoryId.value = value;
};

const onCloseModal = () => {
  editEnergyEfficiencyModal.value = false;
  findKeywords();
};

const onClickCategoryMap = (category) => {
  selectedFilter.value = category;
  handleCategorySelection(category);
};
</script>

<style lang="scss" scoped>
.input_field {
  :deep(.v-input__control) {
    .v-field {
      border-radius: 8px;
      border: 1px solid rgba($buttonText, 0.4);
      background-color: transparent;
      .v-field__field {
        .v-field__input {
          column-gap: 7px;
          padding: 8px 16px;
          min-height: auto;
          @include fluidFont(12, 12, 1.3);
          font-weight: 500;
          color: rgba($blueDark, 1);
          &::placeholder {
            color: rgba($blueDark, 1);
            @include fluidFont(12, 12, 1.3);
            font-weight: 500;
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
    .v-selection-control-group {
      .v-selection-control.v-selection-control--dirty {
        .v-selection-control__wrapper {
          .v-selection-control__input {
            .v-icon {
              color: rgba($SecondaryBrightBlue, 1);
            }
          }
        }
      }
    }
  }
}
</style>
