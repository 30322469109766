<template>
  <CommonDialog
    v-model="openReviewModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['job_rating_modal']"
    width="548px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="modal__header">
          <div class="modal__header-top">
            <h4 v-if="reviewType === 'phase'">
              Rate your Experience for
              <span>{{ estimatePhaseDetails.name }}</span> phase.
            </h4>
            <h4 v-else>Rate your Experience</h4>
            <v-btn
              variant="plain"
              icon="mdi-close"
              :disabled="isLoader"
              @click="closeModal"
            ></v-btn>
          </div>
          <p>
            Please share your honest feedback for the experience you had with
            the project owner.
          </p>
        </div>

        <div class="modal__body">
          <div
            class="modal__body-item"
            v-for="(tag, tagIdx) of getProjectRatingTags"
            :key="tag.id"
          >
            <h3>{{ tag.label }}</h3>
            <div class="modal__body-item-inner">
              <button
                :disabled="isLoader"
                v-for="status of RatingStatusData"
                :key="status.rating"
                type="button"
                class="rating-button"
                :class="{
                  active:
                    projectRatingData.ratingStatusList[tagIdx]?.rating ===
                    status.rating,
                }"
                @click="onClickRatingStatus(tag, status)"
              >
                {{ status.label }}
              </button>
            </div>
          </div>

          <div class="input_field modal__body-item">
            <label>Description</label>
            <textarea
              style="resize: none"
              class="tw-w-full tw-border tw-border-solid tw-border-[#0C0F4A80] tw-rounded-md tw-p-3"
              bg-color="white"
              base-color="white"
              density="compact"
              flat
              placeholder="Write description.."
              no-resize
              rows="4"
              auto-grow
              v-model="projectRatingData.description"
            ></textarea>
          </div>
        </div>

        <div class="modal__footer">
          <v-btn
            :disabled="isLoader"
            class="button button-purple-border"
            @click="closeModal"
            >Cancel</v-btn
          >
          <v-btn
            class="button button-orange"
            @click="onSubmitProjectRating(true)"
            :loading="isLoader"
            :disabled="isLoader"
            >Submit</v-btn
          >
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import ProjectRatingService from "@/core/services/project-rating.service";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { ref, defineEmits, reactive, computed, onMounted } from "vue";
import {
  ProjectRatingTypeEnum,
  PropertySideRatingTags,
  RatingStatusData,
  TradeSideRatingTags,
} from "@/core/enums/ProjectsEnum";
import { parseJSON } from "@/core/utils/common";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { useStore } from "vuex";

const emits = defineEmits(["on-close"]);
const props = defineProps([
  "type",
  "ratingData",
  "projectId",
  "reviewType",
  "estimatePhaseDetails",
]);
const store = useStore();

const getProjectRatingTags = computed(() =>
  props.type === ProjectRatingTypeEnum.TRADER_OWNER
    ? TradeSideRatingTags
    : PropertySideRatingTags
);

const isLoader = ref(false);
const projectRatingData = reactive({
  ratingStatusList: [],
  description: null,
  avgRating: null,
  type: props.type,
  projectId: props.projectId,
});
const openReviewModal = ref(true);

const closeModal = () => {
  onSubmitProjectRating(false);
};

const getAverageRate = () => {
  const totalRating = projectRatingData.ratingStatusList.reduce(
    (sum, status) => {
      if (status) sum += status.rating;
      return sum;
    },
    0
  );
  return (
    Math.round((totalRating * 100) / getProjectRatingTags.value.length) / 100
  );
};
const onSubmitProjectRating = async (isSubmit) => {
  try {
    const ratingData = {
      ...projectRatingData,
      ratingStatusList: isSubmit
        ? projectRatingData.ratingStatusList.filter((status) => status)
        : [],
      avgRating: isSubmit ? getAverageRate() : 0,
      description: isSubmit ? projectRatingData.description : null,
    };

    if (props.ratingData && props.ratingData.id && isSubmit) {
      let updateResponse = null;
      if (props.reviewType === "phase") {
        updateResponse = await ProjectRatingService.updateProjectPhaseRating(
          props.ratingData.id,
          ratingData
        );
      } else {
        updateResponse = await ProjectRatingService.updateProjectRating(
          props.ratingData.id,
          ratingData
        );
      }
      return emits("on-close", updateResponse);
    } else if (!props.ratingData) {
      let response = null;
      if (props.reviewType === "phase") {
        ratingData["estimatePhaseId"] = props.estimatePhaseDetails.id;
        response = await ProjectRatingService.createProjectPhaseRating(
          ratingData
        );
      } else {
        response = await ProjectRatingService.createProjectRating(ratingData);
      }
      return emits("on-close", response);
    } else {
      return emits("on-close", null);
    }
  } catch (error) {
    emits("on-close", null);
  }
};
const onClickRatingStatus = (tag, status) => {
  projectRatingData.ratingStatusList[tag.id - 1] = {
    tag: tag.label,
    rating: status.rating,
  };
};
onMounted(() => {
  if (props.ratingData?.length) {
    const ratingData = JSON.parse(JSON.stringify(props.ratingData));
    projectRatingData.avgRating = ratingData.avgRating;
    projectRatingData.description = ratingData.description;
    projectRatingData.ratingStatusList =
      parseJSON(ratingData.ratingStatusList) || [];
  }
});
</script>
<script>
export default {
  name: "ProjectRateModal",
};
</script>
