import { Commit } from "vuex";
import { CreateSafetyCheckInterface } from "@/core/models/property";
import propertyService from "@/core/services/property.service";
import propertyMapLayersService from "@/core/services/property-map-layers.service";
import {
  getImageStringToImageURL,
  getStatusBasedOnExpiryDateOfSafetyStandard,
} from "@/core/utils/common";

export default {
  setPropertyForm({ commit }: { commit: Commit }, value: any) {
    commit("setPropertyForm", value);
  },

  setProperties({ commit }: { commit: Commit }, value: any) {
    commit("setProperties", value);
  },

  setPropertyDetails({ commit }: { commit: Commit }, value: any) {
    commit("setPropertyDetails", value);
  },

  setSelectedProperties({ commit }: { commit: Commit }, value: any) {
    commit("setSelectedProperties", value);
  },

  setAssetRegisterForm({ commit }: { commit: Commit }, value: any) {
    commit("setAssetRegisterForm", value);
  },

  setAssetRegisters({ commit }: { commit: Commit }, value: any) {
    commit("setAssetRegisters", value);
  },

  setAssetRegisterDetails({ commit }: { commit: Commit }, value: any) {
    commit("setAssetRegisterDetails", value);
  },

  setSelectedAsset({ commit }: { commit: Commit }, value: any) {
    commit("setSelectedAsset", value);
  },

  setPropertyProjects({ commit }: { commit: Commit }, value: any) {
    commit("setPropertyProjects", value);
  },
  // ADD PROPERTY FLOW
  async createProperty({ commit }: { commit: Commit }, formData: any) {
    return propertyService.createProperty(formData).then((response) => {
      return response;
    });
  },

  async deleteProperty({ commit }: { commit: Commit }, propertyId: number) {
    await propertyService.deleteProperty(propertyId);
  },

  async fetchProperties({ commit }: { commit: Commit }, params: any) {
    try {
      const response = await propertyService.fetchProperties(params);
      commit("setProperties", response?.data);
      return response;
    } catch (error) {
      commit("setProperties", []);
      return [];
    }
  },

  async fetchPropertyDetails(
    { commit }: { commit: Commit },
    propertyId: string
  ) {
    try {
      const propertyDetails = (await propertyService.fetchPropertyDetails(
        propertyId
      )) as any;
      const property = propertyDetails?.localData;
      const updatePropertiesData = {
        ...propertyDetails,
        localData: {
          ...propertyDetails.localData,
          publicUrl:
            property?.propertyImage &&
            (await getImageStringToImageURL(property?.propertyImage)),
        },
      };

      commit("setPropertyDetails", updatePropertiesData);
    } catch (error) {
      console.log("🚀 ~ fetchPropertyDetails ~ error:", error);
    }
  },

  async fetchEPCDataList(
    { commit }: { commit: Commit },
    payload: { propertyId: string; params: any }
  ) {
    try {
      const epcCategoryDataList = await propertyService.fetchEPCDataList(
        payload.propertyId,
        payload.params || {}
      );
      commit("setEPCCategoryDataList", epcCategoryDataList);
      return epcCategoryDataList;
    } catch (error) {
      console.log("🚀 ~ fetchPropertyDetails ~ error:", error);
    }
  },

  async fetchEPCDataListByCategoryId(
    { commit }: { commit: Commit },
    propertyEpcDataId: string
  ) {
    try {
      const epcDataListByCategoryId =
        await propertyService.fetchEPCDataListByCategoryId(propertyEpcDataId);
      commit("setEPCDataListByCategoryId", epcDataListByCategoryId);
      return epcDataListByCategoryId;
    } catch (error) {
      console.log("🚀 ~ fetchPropertyDetails ~ error:", error);
    }
  },

  // ASSET REGISTER MANAGEMENT
  async createAssetRegister({ commit }: { commit: Commit }, formData: any) {
    return propertyService.createAssetRegister(formData).then((response) => {
      return response;
    });
  },

  async updateAssetRegister({ commit }: { commit: Commit }, payload: any) {
    const { assetId, formData } = payload;
    return propertyService
      .updateAssetRegister(assetId, formData)
      .then((response) => {
        return response;
      });
  },

  async deleteAssetRegister({ commit }: { commit: Commit }, assetId: string) {
    try {
      await propertyService.deleteAssetRegister(assetId);
    } catch (error) {
      console.log("🚀 ~ delete asset ~ error:", error);
    }
  },

  async fetchAssetRegisters({ commit }: { commit: Commit }, payload: any) {
    const { propertyId, params } = payload;
    return propertyService
      .fetchAssetRegisters(propertyId, params)
      .then((response) => {
        commit("setAssetRegisters", response?.data);
        return response;
      });
  },

  async fetchAssetRegisterDetails(
    { commit }: { commit: Commit },
    assetId: string
  ) {
    try {
      const assetRegisterDetails =
        await propertyService.fetchAssetRegisterDetails(assetId);
      commit("setAssetRegisterDetails", assetRegisterDetails);
      return assetRegisterDetails;
    } catch (error) {
      console.log("🚀 ~ fetchAssetRegisterDetails ~ error:", error);
    }
  },

  async fetchPropertyProjects({ commit }: { commit: Commit }, payload: any) {
    const { propertyId, params } = payload;
    return propertyService
      .fetchPropertyProjects(propertyId, params)
      .then((response) => {
        commit("setPropertyProjects", response?.data || []);
        return response;
      });
  },
  async fetchPropertyLeasesData({ commit }: { commit: Commit }, payload: any) {
    const { propertyId } = payload;

    return propertyService
      .fetchPropertyLeasesData(propertyId)
      .then((response) => {
        commit("setPropertyLeasesData", response?.data || null);
        return response;
      });
  },

  async fetchPolygonCoordinates({ commit }: { commit: Commit }, payload: any) {
    const { latitude, longitude, width, height, response_version } = payload;
    try {
      const coordinateDetails = await propertyService.fetchPolygonCoordinates(
        latitude,
        longitude,
        width,
        height,
        response_version
      );
      return coordinateDetails;
    } catch (error) {
      console.log("🚀 ~ fetchPolygonCoordinates ~ error:", error);
      throw error;
    }
  },
  async fetchPropertyPolygon({ commit }: { commit: Commit }, payload: any) {
    const { uprnId, classCode } = payload;
    try {
      // const response = await propertyService.fetchPropertyPolygon(uprnId);
      const response =
        await propertyService.fetchPropertyPolygonWithClassAndUprn(
          uprnId,
          classCode
        );
      return response;
    } catch (error) {
      console.log("🚀 ~ fetchPropertyPolygon ~ error:", error);
    }
  },
  async fetchComparables({ commit }: { commit: Commit }, payload: any) {
    const { params, uprn } = payload;
    return propertyService.fetchComparables(params, uprn);
  },

  async fetchPropertyPropbar({ commit }: { commit: Commit }, uprn: any) {
    return propertyService.fetchPropertyPropbar(uprn);
  },

  async createRicsReport({ commit }: { commit: Commit }, payload: any) {
    const { formData, propertyId } = payload;
    return propertyService
      .createRicsReport(formData, propertyId)
      .then((response) => {
        return response;
      });
  },

  async fetchRicsReports({ commit }: { commit: Commit }, payload: any) {
    const { params, propertyId } = payload;
    return propertyService.fetchRicsReports(params, propertyId);
  },

  async createPropertyInsurance({ commit }: { commit: Commit }, payload: any) {
    const { formData, propertyId } = payload;
    return propertyService
      .createPropertyInsurance(formData, propertyId)
      .then((response) => {
        return response;
      });
  },

  async fetchPropertyInsurances(
    { commit }: { commit: Commit },
    propertyId: any
  ) {
    return propertyService.fetchPropertyInsurances(propertyId);
  },

  async updateEfficiencyRating({ commit }: { commit: Commit }, payload: any) {
    return propertyService.updateEfficiencyRating(payload).then((response) => {
      return response;
    });
  },

  async createPropertyNewLayer({ commit }: { commit: Commit }, payload: any) {
    return propertyMapLayersService
      .createPropertyNewLayer(payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
      });
  },
  async getPropertyLayers({ commit }: { commit: Commit }, propertyId: any) {
    const response =
      await propertyMapLayersService.getPropertyMapLayersByPropertyId(
        propertyId
      );
    commit("setPropertyMapLayers", response);
    return response;
  },
  async getPropertyLayerData({ commit }: { commit: Commit }, layerId: any) {
    const response = await propertyMapLayersService.getPropertyMapLayerData(
      layerId
    );

    return response;
  },

  async createPropertyLayerData({ commit }: { commit: Commit }, payload: any) {
    return propertyMapLayersService
      .createPropertyLayerData(payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
      });
  },

  async deletePropertyLayer({ commit }: { commit: Commit }, layerId: number) {
    try {
      await propertyMapLayersService.deletePropertyLayer(layerId);
    } catch (error) {
      console.log("🚀  delete property Layer  error:", error);
    }
  },

  async createPropertySafetyCheck(
    { commit }: { commit: Commit },
    payload: { data: CreateSafetyCheckInterface; propertyId: number }
  ) {
    try {
      return await propertyService.createSafetyCheck(payload);
    } catch (error) {
      console.log("error in creating safety checks");
      return null;
    }
  },
  async updatePropertySafetyCheck(
    { commit }: { commit: Commit },
    payload: {
      data: CreateSafetyCheckInterface;
      propertyId: number;
      safetyCheckId: number;
    }
  ) {
    try {
      return await propertyService.updateSafetyCheck(payload);
    } catch (error) {
      console.log("error in update safety checks");
      return null;
    }
  },
  async getPropertySafetyCheck(
    { commit }: { commit: Commit },
    propertyId: number
  ) {
    try {
      const response: any = await propertyService.getPropertySafetyCheckList(
        propertyId
      );
      response.forEach((element: any) => {
        element["status"] = getStatusBasedOnExpiryDateOfSafetyStandard(
          element.expiryDate
        );
      });

      commit("setPropertySafetyCheckList", response);
      return response;
    } catch (error) {
      console.log("error in getting safety checks");
      return [];
    }
  },
  async getHeatPumpSubscription(
    { commit }: { commit: Commit },
    payload: { userId: number; propertyId: number }
  ) {
    try {
      const response: any = await propertyService.getHeatPumpSubscription(
        payload
      );

      return response;
    } catch (error) {
      console.log("error in getting safety checks");
      throw error;
    }
  },
};
