<template>
  <main
    class="material-form__wrapper tw-px-5 tw-py-6 estimate-body tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4"
  >
    <el-form
      class="tw-flex tw-flex-col tw-gap-4 tw-w-full"
      :model="estimateForm.projectStages[phaseIndex]"
    >
      <section class="tw-flex tw-gap-4 tw-items-center tw-justify-between">
        <div class="tw-flex tw-gap-4 tw-items-center tw-w-full tw-max-w-[50%]">
          <el-form-item
            class="tw-w-full tw-max-w-[80px] tw-border-b-[1px] tw-border-[rgba(12,15,74,0.2)] tw-border-0 material__section"
          >
            <NoBgSelect
              :disabled="isFormReadOnly"
              v-model="estimateForm.projectStages[phaseIndex].stageType"
              class="tw-w-full"
              @change="onChangeStageType"
            >
              <template #option>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </template>
            </NoBgSelect>
          </el-form-item>
        </div>
      </section>
      <section
        v-if="!isDIYProjectType"
        class="tw-flex tw-gap-4 tw-items-center tw-w-full md:tw-flex-col md:tw-items-start"
      >
        <div class="tw-flex tw-gap-4 tw-items-center md:tw-w-full">
          <el-form-item
            class="tw-max-w-[141px] md:tw-w-1/2 md:tw-max-w-full"
            :class="{ 'is-error': !!errors.subTotal }"
          >
            <div class="tw-flex tw-items-center tw-w-full tw-gap-2">
              <Text variant="p">£</Text>
              <NoBgInput
                :class="{ errormsg: validCost }"
                @input="handleSubTotal($event)"
                :disabled="isAddNewMaterialItem"
                @keypress="
                  numChecker(
                    $event,
                    estimateForm.projectStages[phaseIndex].subTotal
                  )
                "
                :readonly="isFormReadOnly"
                v-model="estimateForm.projectStages[phaseIndex].subTotal"
                :formatter="
                  (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                :parser="(value) => value.replace(/^0+(?=\d)|\$|,/g, '')"
                placeholder="Materials subtotal"
              />
            </div>
          </el-form-item>
          <el-form-item class="tw-max-w-[200px] md:tw-w-1/2 md:tw-max-w-full">
            <div class="tw-flex tw-flex-row tw-w-full">
              <NoBgInput
                :readonly="true"
                placeholder="VAT"
                v-model="state.vatComputation"
              />
              <NoBgSelect
                :disabled="isFormReadOnly"
                v-model="estimateForm.projectStages[phaseIndex].vat"
                class="tw-max-w-[55px]"
              >
                <template #option>
                  <el-option
                    v-for="item in vatOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </template>
              </NoBgSelect>
            </div>
          </el-form-item>
        </div>
        <div class="tw-flex tw-gap-3 tw-items-center">
          <Text
            variant="span"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            whiteSpace="nowrap"
            >Total material cost:</Text
          >
          <LabelWithValue
            class=""
            :justifyStart="true"
            :data="formattedCurrency(totalPhaseEstimateCost)"
            label=""
          />
        </div>
      </section>
    </el-form>

    <v-divider></v-divider>
    <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
      <div class="tw-flex tw-gap-2 tw-items-center tw-justify-start">
        <Text variant="p" whiteSpace="nowrap">Items</Text>
        <div>
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="bottom"
            class="estimate_tooltip"
          >
            Detailed Items
          </v-tooltip>
          <InfoIcon />
        </div>
      </div>
      <v-divider></v-divider>
      <HistoricalMaterialItemForm
        v-for="(item, index) in estimateForm.projectStages[phaseIndex]
          .projectStageTasks"
        :itemIndex="index"
        :key="index"
        :phaseIndex="phaseIndex"
        :item="item"
        :isDIYProjectType="isDIYProjectType"
      />
    </div>
    <v-divider
      v-if="estimateForm.projectStages[phaseIndex].projectStageTasks?.length"
    ></v-divider>
    <div class="tw-w-full tw-flex tw-justify-start">
      <Button
        variant="button-custom "
        :isCapitalize="true"
        :label="itemActionButtonLabel"
        class="!tw-w-[auto] blue-text tw-text-[#0C0F4A]"
        :ripple="false"
        @click="addItem"
      >
        <template #prefix>
          <PlusFilledYellowIcon />
        </template>
      </Button>
    </div>
  </main>
  <ConfirmPhaseTotal
    v-if="isShowConfirmModal"
    @on-close="onCloseModal"
    @on-confirm="onConfirmModal"
  />
</template>
<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import PlusFilledYellowIcon from "@/core/components/icons/PlusFilledYellowIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import NoBgInput from "@/core/components/ui/inputs/NoBgInput.vue";
import NoBgSelect from "@/core/components/ui/inputs/NoBgSelect.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import AddPropertyForm from "@/modules/jobs/components/estimates/form/AddPropertyForm.vue";
import HistoricalMaterialItemForm from "@/modules/jobs/components/estimates/form/HistoricalMaterialItemForm.vue";
import estimate from "@/core/constants/estimate";
import { isNumber } from "@/core/helpers/number.helper";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { getRandomColor } from "@/core/utils/common";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import ConfirmPhaseTotal from "@/modules/jobs/components/modal/ConfirmPhaseTotal.vue";
const store = useStore();
const emits = defineEmits([
  "on-update-phase-Estimation",
  "on-update-material-task",
]);
const props = defineProps({
  phaseIndex: Number,
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
});

const options = [
  {
    value: estimate.LABOUR,
    label: "Labour",
  },
  {
    value: estimate.MATERIAL,
    label: "Materials",
  },
];

const vatOptions = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 0.05,
    label: "5%",
  },
  {
    value: 0.2,
    label: "20%",
  },
];
const state = reactive({
  vatComputation: null,
});

const isShowConfirmModal = ref(false);
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);

const itemActionButtonLabel = computed(() => {
  return estimateForm.value.projectStages[props.phaseIndex].projectStageTasks
    .length
    ? "Add another item"
    : "Add item";
});

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const numChecker = (event, value) => {
  return isNumber(event, value);
};

const addItem = () => {
  if (!isFormReadOnly.value) {
    if (
      !estimateForm.value.projectStages[props.phaseIndex].projectStageTasks
        .length
    ) {
      isShowConfirmModal.value = true;
      return;
    }

    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks.push({
      id: null,
      name: "",
      taskCost: "0",
      amountToDisplay: "",
      duration: null,
      durationType: 1,
      total: 0,
      startDate: null,
      endDate: null,
      note: "",
      materialUnit: "0",
    });
    updateMaterialPhaseEstimation();
  }
};
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const onChangeStageType = (value) => {
  const optionType = value == "labour" ? "labour" : "materials";
  const projectStage = {
    id: null,
    name: "",
    stageType: optionType,
    totalDuration: 0,
    durationType: 1,
    stageCost: 0,
    projectStageTasks: [],
    requiredDeposit: false,
    vat: 0,
    subTotal: "0",
    subTotalDisplay: "",
    note: "",
    startDate: null,
    phaseOrder: estimateForm.value.projectStages[props.phaseIndex].phaseOrder,
    endDate: null,
    estimationPhaseProperties: [],
    color: getRandomColor(),
    attachments: [],
    user: { id: user.value?.id },
    workStation: { id: activeUserWorkstation.value?.id },
  };

  onDeletePhase(estimateForm.value.projectStages[props.phaseIndex]?.id);
  store.dispatch(`${JOB_TEMPLATE_STORE}/setProjectStage`, {
    phaseIndex: props.phaseIndex,
    defaultProjectStageFormValue: projectStage,
  });
};
const onDeletePhase = async (id) => {
  if (id) {
    try {
      await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhase`, {
        estimateId: id,
      });
    } catch (error) {
      console.log();
    }
  }
};

const validCost = computed(() => {
  const MAX_LIMIT = 99999999;
  const cost = estimateForm.value.projectStages[props.phaseIndex].subTotal;
  if (cost > MAX_LIMIT) {
    return true;
  }
  return false;
});
const totalPhaseEstimateCost = computed(() => {
  const phase = estimateForm.value.projectStages[props.phaseIndex];
  const newValue = phase.vat
    ? parseFloat(phase.subTotal) + parseFloat(phase.subTotal) * phase.vat
    : phase.subTotal;
  return newValue;
});
const handleSubTotal = (value) => {
  if (value) {
    if (!validCost.value) updateMaterialPhaseEstimation();
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "subtotal",
    });
  } else {
    estimateForm.value.projectStages[props.phaseIndex].subTotal = "0";
  }
};
const isAddNewMaterialItem = computed(() => {
  return estimateForm.value.projectStages[props.phaseIndex].projectStageTasks
    .length;
});
const updateMaterialPhaseEstimation = () => {
  emits("on-update-phase-Estimation");
};

const errors = computed(() => {
  if (
    !store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`][
      props.phaseIndex
    ]
  ) {
    return store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
  }
  return store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`][
    props.phaseIndex
  ];
});

const onCloseModal = () => {
  isShowConfirmModal.value = false;
};
const onConfirmModal = async () => {
  estimateForm.value.projectStages[props.phaseIndex].subTotal = "0";
  store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseCost`, {
    phaseIndex: props.phaseIndex,
    newValue: 0,
  });
  estimateForm.value.projectStages[props.phaseIndex].projectStageTasks.push({
    id: null,
    name: "",
    taskCost: "0",
    amountToDisplay: "",
    duration: null,
    durationType: 1,
    total: 0,
    startDate: null,
    endDate: null,
    note: "",
    materialUnit: "0",
  });
  updateMaterialPhaseEstimation();
  onCloseModal();
};
onMounted(() => {
  estimateForm.value.projectStages[props.phaseIndex].phaseOrder =
    props.phaseIndex;
});
</script>
<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}
.errormsg {
  border: 1px solid red;
}
.is-error input,
.is-error select {
  border-color: red;
}
.el-select__placeholder {
  color: rgba($blueDark, 1);
}

:deep(.el-input) {
  .el-input.is-disabled {
    .el-input__wrapper {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

:deep(.el-form-item__content) {
  border-bottom: 1px solid rgba($blueDark, 0.2);
}

:deep(.el-select__wrapper.is-hovering):not(.is-focused) {
  box-shadow: none !important;
}

:deep(.el-input.is-disabled) .el-input__wrapper {
  background-color: transparent;
  box-shadow: none;
}
:deep(.el-input__inner),
:deep(.el-input.is-disabled) .el-input__inner {
  @include fluidFont(12, 12, 1.2);
  font-weight: 500;
  color: rgba($blueDark, 1);
}

.button-custom {
  width: auto !important;
  color: rgba($blueDark, 1);
  @include fluidFont(12, 12, 1.2);
  background-color: transparent;
}
</style>
