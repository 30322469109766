<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="600px"
    max-width="100%"
    :className="['setting_up_preview_modal']"
  >
    <template v-slot:body>
      <div class="modal_content">
        <v-icon
          icon="mdi-close"
          class="close_icon"
          @click="onClickCloseButton"
        />
        <h4>Great job! 🥳</h4>
        <img src="../../../../assets/images/complete-id.png" alt="" />
        <p>
          Now, for your safety and others<br />
          we now need to, complete your ID verification (KYC)
        </p>

        <v-btn
          variant="elevated"
          class="button button-orange w-full"
          @click="onClickStatusModalContinue"
        >
          Complete ID Verification
        </v-btn>
        <v-btn
          @click="onClickCloseButton"
          variant="plain"
          class="blue-text"
          color="blue"
          >cancel</v-btn
        >
      </div>
    </template>
  </CommonDialog>
  <WorkstationVerificationModal
    v-if="openedModel === modelName.VERIFYMODAL"
    :onClickCloseTab="onClickCloseModel"
  />
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: {
    CommonDialog,
    WorkstationVerificationModal,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const modelName = {
      ADDRESSMODAL: "addressModal",
      VERIFYMODAL: "verifyModal",
    };
    const openedModel = ref("");
    const currentStatusModalName = ref("");
    const statusModal = { PAYMENT: "payment", KYC: "kyc" };

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    const onClickStatusModalContinue = async () => {
      try {
        const url = new URL(window.location.href);
        url.search = "";
        if (currentStatusModalName.value === statusModal.PAYMENT) {
          const response = await store.dispatch(
            `${WORKSTATION}/getWorkstationVerificationData`,
            activeUserWorkstation.value?.id
          );
        }
        history.replaceState(null, "", url.toString());
        openedModel.value = "verifyModal";
      } catch (error) {
        console.log();
      }
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      ctx.emit("onClickCloseTab");
    };

    return {
      onClickCloseButton,
      onClickStatusModalContinue,
      modelName,
      openedModel,
      onClickCloseModel,
      currentStatusModalName,
      statusModal,
    };
  },
};
</script>
