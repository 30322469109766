<template>
  <Card class="!tw-min-h-[440px] tw-p-4 tw-box-border">
    <template #content>
      <main
        class="estimate-revision-summary tw-flex tw-flex-col tw-justify-between tw-gap-4 tw-items-start tw-w-full tw-h-full"
      >
        <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-w-full">
          <div
            class="tw-flex tw-items-center tw-w-full tw-justify-start tw-gap-2"
          >
            <Text variant="h6" whiteSpace="nowrap">
              {{
                isHistoricalProject ? "Cost Summary" : "Your Estimate Summary"
              }}</Text
            >
            <div>
              <v-tooltip
                max-width="242px"
                activator="parent"
                location="top"
                class="estimate_tooltip tw-cursor-pointer"
              >
                {{
                  isHistoricalProject ? "Cost Summary" : "Your Estimate Summary"
                }}
              </v-tooltip>

              <v-icon
                icon="mdi-information-slab-circle-outline mdi"
                color="#80829F"
              ></v-icon>
            </div>
          </div>
          <EstimateSummaryData :isHistoricalProject="isHistoricalProject" />
          <Button
            v-if="IsFinalEstimateSubmitted && project?.siteVisitStatus"
            variant="secondary"
            label="Request site visit"
            @click="onRequestSiteVisit"
          />
          <div
            class="estimate-revision-summary__phase-content tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-2 tw-pr-3 tw-max-h-[370px] tw-overflow-auto"
          >
            <SmallPhaseItem
              v-for="(estimate, index) in estimateForm.projectStages"
              :estimate="estimate"
              :phaseIndex="index"
              :isHistoricalProject="isHistoricalProject"
              :key="index"
            />
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-gap-7 tw-w-full">
          <Button
            variant="secondary"
            :label="grantLabel"
            @click="onOpenAddGrantModal"
            :disabled="
              isSubContractor ||
              isLockTemplateModifiedChecker ||
              !isUserHasEstimateEditAccess
            "
          />
          <EstimateTotal
            :isProperty="isProperty"
            @on-submit="onSubmitEstimate"
            @on-route-to-schedule="onRouteToSchedule"
            :totalSubTotal="totalSubTotal"
            :totalVat="totalVat"
            :serviceCharges="serviceCharges"
            :isDIYProjectType="isDIYProjectType"
          />
        </div>

        <!-- MODALS -->
        <SelectDepositModal
          v-if="state.showSelectDepositModal"
          :sumTotal="true"
          :estimate="estimateForm.projectStages"
          :totalSubTotal="totalSubTotal"
          :totalVat="totalVat"
          :serviceCharges="serviceCharges"
          @on-confirm="onConfirmSelectDepositModal"
          @on-close="onCloseSelectDepositModal"
        />
        <SubmitEstimateConfirmationModal
          v-if="state.showSubmitEstimateConfirmationModal"
          @on-save="onSubmitEstimateConfirmationModal"
          @on-close="onCloseSubmitEstimateConfirmationModal"
        />
        <AcceptEstimateModal
          v-if="state.showAcceptEstimateModal"
          @on-close="onCloseAcceptEstimateModal"
        />
        <ServiceAgreementModal
          v-if="state.showServiceAgreementModal"
          type="contactor"
          :project="project"
          :projectEstimate="projectEstimate"
          @on-confirm="onConfirmServiceAgreementModal"
          @on-close="onCloseServiceAgreementModal"
        />
        <ConfirmJobModal
          v-if="state.showConfirmJobModal"
          @on-close="onCloseConfirmJobModal"
        />

        <AddGrantModal
          v-if="state.showAddGrandModal"
          :totalSubTotal="totalSubTotal"
          :totalVat="totalVat"
          :serviceCharges="serviceCharges"
          :isDIYProjectType="isDIYProjectType"
          @on-close="onCloseAddGrantModal"
          @on-save-project-estimation="updateProjectEstimation"
        />
        <!-- <SiteVisitRequestDialog
          v-if="state.showRequestSiteVisitModal"
          :projectEstimateId="projectEstimateId"
          :projectId="project?.id"
          @on-close="onCloseRequestSiteVisitModal"
        /> -->
        <RequestSiteVisitModal
          v-if="isShowSiteVisitRequestModal"
          @on-close="onCloseAllSiteVisitModal"
          @on-request-a-site-visit="onOpenSiteVisitRequestFormModal"
        />

        <RequestSiteVisitFormModal
          v-if="isShowSiteVisitRequestFormModal"
          @on-close="onCloseAllSiteVisitModal"
          @on-back="onOpenSiteRequestVisitModal"
          @on-submit-success="onOpenSuccessSiteVisitRequestModal"
        />

        <SuccessSiteVisitRequestModal
          v-if="isShowSuccessSiteVisitRequestModal"
          @on-close="onCloseAllSiteVisitModal"
          @on-continue="onOpenSiteVisitCreditsModal"
        />

        <EarnCreditsModal
          v-if="isShowSiteVisitCreditsModal"
          @on-close="onCloseAllSiteVisitModal"
        />

        <FinalEstimateSubmitLoadingModal
          v-if="finalEstimateLoadingModal.visible"
          :step="finalEstimateLoadingModal.step"
          @onCancel="onFinalEstimateSubmitLoadingCancel"
        />

        <ConfirmRaiseProject
          v-if="isOpenRaiseProjectModal"
          @on-close="onCloseRaiseProjectModal"
          @on-confirm="onConfirmRaiseProjectModal"
        />
      </main>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import SiteVisitRequestDialog from "@/core/uni-components/SiteVisitRequestDialog.vue";
import EstimateSummaryData from "@/modules/jobs/components/estimates/EstimateSummaryData.vue";
import Button from "@/core/components/ui/general/Button.vue";
import EstimateTotal from "@/modules/jobs/components/estimates/EstimateTotal.vue";
import SelectDepositModal from "@/modules/jobs/components/modal/SelectDepositModal.vue";
import SubmitEstimateConfirmationModal from "@/modules/jobs/components/modal/SubmitEstimateConfirmationModal.vue";
import AcceptEstimateModal from "@/modules/jobs/components/modal/AcceptEstimateModal.vue";
import ServiceAgreementModal from "@/modules/jobs/components/modal/ServiceAgreementModal.vue";
import ConfirmJobModal from "@/modules/jobs/components/modal/ConfirmJobModal.vue";
import AddGrantModal from "@/modules/jobs/components/modal/AddGrantModal.vue";
import SmallPhaseItem from "@/modules/jobs/components/estimates/SmallPhaseItem.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import projectEstimationService from "@/core/services/project-estimation.service";
import {
  AgreeTermsConsumerDisclosure,
  EstimatePhaseStatus,
  EstimateTypeForDeposit,
} from "@/core/enums/estimateEnum";
import { ElMessage } from "element-plus";
import { USER_STORE } from "@/store/modules/user";
import { JOBS_STORE } from "@/store/modules/jobs";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  getCurrentConTractorPhases,
  getSubContractorPhases,
} from "@/core/utils/common";
import FinalEstimateSubmitLoadingModal from "../modal/FinalEstimateSubmitLoadingModal.vue";
import ConfirmRaiseProject from "../modal/ConfirmRaiseProject.vue";
import { PropertyWorkflowTypeEnum } from "@/core/enums/PropertiesEnum";
import projectService from "@/core/services/project.service";
import { ProjectMetaDataStatusEnum } from "@/core/enums";
import { PROJECT_STORE } from "@/store/modules/project";
import { JOBS_ROUTE } from "../../routes";
import { useRouter } from "vue-router";
import RequestSiteVisitModal from "@/core/components/modals/projects/RequestSiteVisitModal.vue";
import RequestSiteVisitFormModal from "@/core/components/modals/projects/RequestSiteVisitFormModal.vue";
import SuccessSiteVisitRequestModal from "@/core/components/modals/projects/SuccessSiteVisitRequestModal.vue";
import EarnCreditsModal from "@/core/components/modals/projects/EarnCreditsModal.vue";

const store = useStore();
const router = useRouter();

const emits = defineEmits([
  "on-save-estimation",
  "onFinalSave",
  "on-route-to-schedule",
]);

const isShowSiteVisitRequestModal = ref(false);
const isShowSiteVisitRequestFormModal = ref(false);
const isShowSuccessSiteVisitRequestModal = ref(false);
const isShowSiteVisitCreditsModal = ref(false);

const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const grantLabel = computed(() => {
  return !formData.value.grantType ? "Add grant" : "Edit grant";
});
const workFlowType = computed(
  () => store.getters[`${JOBS_STORE}/getWorkFlowType`]
);
const isOpenRaiseProjectModal = ref(false);
const onCloseRaiseProjectModal = () => {
  isOpenRaiseProjectModal.value = false;
};
const onConfirmRaiseProjectModal = () => {
  isOpenRaiseProjectModal.value = false;
  state.showServiceAgreementModal = true;
};
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const isFormError = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getFromErrors`]
);
const IsFinalEstimateSubmitted = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/IsFinalEstimateSubmitted`]
);

const totalSubTotal = computed(() => {
  const sum = estimateForm.value.projectStages.reduce(
    (sum: number, phase: any) => {
      const roundedSubTotal = parseFloat(phase.subTotal);
      return sum + roundedSubTotal;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const depositModalData = ref(null) as any;
const finalEstimateLoadingModal = reactive({
  visible: false,
  step: 0,
});

const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/lockTemplateModifiedChecker`]
);

const totalVat = computed(() => {
  if (props.isHistoricalProject) return 0;
  const sum = estimateForm.value.projectStages.reduce(
    (sum: number, phase: any, index: number) => {
      const roundedTotalVat = phase.subTotal * phase.vat;
      return sum + roundedTotalVat;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  if (props.isHistoricalProject) return 0;
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee = estimateForm.value.projectStages.reduce(
    (sum: number, phase: any) => {
      const roundedTotalWithServiceFee = phase.subTotal * serviceFeePercentage;
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});

const projectEstimate = computed(() => {
  return {
    ...formData.value,
    projectEstimateCreator: {
      ...user.value,
      workStation: activeUserWorkstation.value,
    },

    projectStages: estimateForm.value?.projectStages,
  };
});

const onRouteToSchedule = async () => {
  emits("on-route-to-schedule");
};

const onOpenSiteRequestVisitModal = () => {
  isShowSiteVisitRequestModal.value = true;
  isShowSiteVisitRequestFormModal.value = false;
  isShowSuccessSiteVisitRequestModal.value = false;
  isShowSiteVisitCreditsModal.value = false;
};
const onOpenSiteVisitRequestFormModal = () => {
  isShowSiteVisitRequestModal.value = false;
  isShowSiteVisitRequestFormModal.value = true;
  isShowSuccessSiteVisitRequestModal.value = false;
  isShowSiteVisitCreditsModal.value = false;
};
const onOpenSuccessSiteVisitRequestModal = () => {
  isShowSiteVisitRequestModal.value = false;
  isShowSiteVisitRequestFormModal.value = false;
  isShowSuccessSiteVisitRequestModal.value = true;
  isShowSiteVisitCreditsModal.value = false;
};
const onOpenSiteVisitCreditsModal = () => {
  isShowSiteVisitRequestModal.value = false;
  isShowSiteVisitRequestFormModal.value = false;
  isShowSuccessSiteVisitRequestModal.value = false;
  isShowSiteVisitCreditsModal.value = true;
};

const onCloseAllSiteVisitModal = () => {
  isShowSiteVisitRequestModal.value = false;
  isShowSiteVisitRequestFormModal.value = false;
  isShowSuccessSiteVisitRequestModal.value = false;
  isShowSiteVisitCreditsModal.value = false;
};

const onRequestSiteVisit = () => {
  if (IsFinalEstimateSubmitted.value && props.project?.siteVisitStatus) {
    onOpenSiteRequestVisitModal();
  }
};

const onSiteVisitRequest = () => {
  if (IsFinalEstimateSubmitted.value && props.project?.siteVisitStatus) {
    state.showRequestSiteVisitModal = true;
  }
};

defineExpose({
  // onSiteVisitRequest,
  onRequestSiteVisit,
});

const props = defineProps({
  project: Object,
  projectEstimateId: Number,
  isProperty: Boolean,
  isHistoricalProject: {
    type: Boolean,
    default: false,
  },
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
});
const state = reactive({
  showSelectDepositModal: false,
  showSubmitEstimateConfirmationModal: false,
  showAcceptEstimateModal: false,
  showServiceAgreementModal: false,
  showConfirmJobModal: false,
  showAddGrandModal: false,
  showRequestSiteVisitModal: false,
});

const hasSubContractorsSubmitted = async () => {
  const subContractors = [] as any;
  const invitedContractorsList =
    store.getters[`${JOB_TEMPLATE_STORE}/invitedContractorsList`];
  invitedContractorsList.map((con: any) => {
    if (con.fromContractorId == activeUserWorkstation.value?.id) {
      subContractors.push(con);
    }
  });
  return !subContractors.some(
    (el: any) =>
      el.agreeTermsConsumerDisclosure == AgreeTermsConsumerDisclosure.NOT_ADDED
  );
};

const onSubmitEstimate = async () => {
  const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`);
  if (
    isValid.flag &&
    !isFormError.value.startDate &&
    !isFormError.value.validationError
  ) {
    const isAccepted = checkIfAllAccepted();

    if (isAccepted.length === 0) {
      const hasSubmiited = await hasSubContractorsSubmitted();
      if (hasSubmiited) {
        if (isSubContractor.value) {
          state.showSubmitEstimateConfirmationModal = true;
        } else {
          //Save Estimation
          state.showSelectDepositModal = true;
          emits("on-save-estimation");
        }
      } else {
        ElMessage.error("Some of the sub-contractors have not submitted yet");
      }
    } else {
      ElMessage.error("please accept or reject the pending phases");
    }
  } else {
    if (isFormError.value.subTotalValidError) {
      ElMessage.error(`The calculated amount for phase is negative.`);
      return;
    }

    ElMessage.error(
      isFormError.value.validationError
        ? `validation failed`
        : isValid.errorMessage
    );
  }
};

const isSubContractor = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsSubContractor`]
);

const onCloseSelectDepositModal = () => {
  state.showSelectDepositModal = false;
};

const projectEstimateInvitation = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimationInvitation`]
);

const onConfirmSelectDepositModal = async (payload: any) => {
  if (payload) {
    depositModalData.value = payload;
    state.showSelectDepositModal = false;

    if (
      workFlowType.value &&
      workFlowType.value === PropertyWorkflowTypeEnum.RAISE_CLIENT
    ) {
      isOpenRaiseProjectModal.value = true;
    } else {
      state.showSubmitEstimateConfirmationModal = true;
    }
  }
};

const onSubmitEstimateConfirmationModal = () => {
  state.showSubmitEstimateConfirmationModal = false;
  state.showServiceAgreementModal = true;
};

const onCloseSubmitEstimateConfirmationModal = () => {
  state.showSubmitEstimateConfirmationModal = false;
};

const onCloseAcceptEstimateModal = () => {
  state.showAcceptEstimateModal = false;
};

const onCloseServiceAgreementModal = () => {
  state.showServiceAgreementModal = false;
};

const onConfirmServiceAgreementModal = async () => {
  const isSubmitProject = await submitEstimate();
  if (isSubmitProject) {
    state.showServiceAgreementModal = false;
  }
};

const onCloseConfirmJobModal = () => {
  state.showConfirmJobModal = false;
};

const onCloseAddGrantModal = () => {
  state.showAddGrandModal = false;
};

const onOpenAddGrantModal = () => {
  state.showAddGrandModal = true;
};
const onCloseRequestSiteVisitModal = () => {
  state.showRequestSiteVisitModal = false;
};

const updateProjectEstimation = () => {
  emits("on-save-estimation");
};
const projectDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const isEstimateSubmit = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateSubmit`]
);
const isUserHasEstimateEditAccess = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isUserHasEstimateEditAccess`]
);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const checkIfAllAccepted = () => {
  const invitedContractorsList =
    store.getters[`${JOB_TEMPLATE_STORE}/invitedContractorsList`];
  const setTotalPhases = store.getters[`${JOB_TEMPLATE_STORE}/getTotalPhases`];

  const subCon = getSubContractorPhases(
    activeUserWorkstation.value?.id,
    invitedContractorsList,
    setTotalPhases
  );
  const currCon = getCurrentConTractorPhases(
    activeUserWorkstation.value?.id,
    setTotalPhases
  );

  const subFinal = subCon.filter((el: any) => {
    let acceptedBy = null;
    if (el.acceptedBy == null) {
      acceptedBy = [];
    } else {
      if (Array.isArray(el.acceptedBy)) {
        acceptedBy = el.acceptedBy;
      } else {
        if (
          typeof el.acceptedBy == "string" &&
          JSON.parse(el.acceptedBy) &&
          Array.isArray(JSON.parse(el.acceptedBy))
        ) {
          acceptedBy = JSON.parse(el.acceptedBy);
        } else {
          acceptedBy = [];
        }
      }
    }

    if (
      [EstimatePhaseStatus.PENDING, EstimatePhaseStatus.WAITING].includes(
        el.phaseStatus
      )
    ) {
      return true;
    }

    if (
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      !acceptedBy.includes(activeUserWorkstation.value?.id)
    ) {
      return true;
    }
    return false;
  });

  const currFinal = currCon.filter((el: any) => {
    let acceptedBy = null;
    if (el.acceptedBy == null) {
      acceptedBy = [];
    } else {
      if (Array.isArray(el.acceptedBy)) {
        acceptedBy = el.acceptedBy;
      } else {
        if (
          typeof el.acceptedBy == "string" &&
          JSON.parse(el.acceptedBy) &&
          Array.isArray(JSON.parse(el.acceptedBy))
        ) {
          acceptedBy = JSON.parse(el.acceptedBy);
        } else {
          acceptedBy = [];
        }
      }
    }
    if (
      el.assignPhaseWorkStation &&
      [EstimatePhaseStatus.PENDING, EstimatePhaseStatus.WAITING].includes(
        el.phaseStatus
      )
    ) {
      return true;
    }

    if (
      !el.assignPhaseWorkStation &&
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      el.workStation.id == activeUserWorkstation.value?.id
    ) {
      return false;
    }

    if (
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      !acceptedBy.includes(activeUserWorkstation.value?.id)
    ) {
      return true;
    }
    return false;
  });

  return [...subFinal, ...currFinal];
};

const updateDepositedPhases = async () => {
  // eslint-disable-next-line
  try {
    if (depositModalData.value) {
      await projectEstimationService.depositPhaseWithEstimateUpdate(
        formData.value?.id,
        depositModalData.value
      );
    } else {
      ElMessage.error("deposits data is null, please select again");
    }
  } catch (error) {
    throw error;
  }
};

const submitEstimate = async () => {
  try {
    if (isSubContractor.value) {
      await projectEstimationService.submitEstimateBySubContractor(
        projectEstimateInvitation.value[0]?.id
      );
      store.dispatch(`${JOB_TEMPLATE_STORE}/setIsEstimateSubmit`, true);
      emits("onFinalSave");
      return true;
    } else {
      finalEstimateLoadingModal.visible = true;
      await updateDepositedPhases();
      await updateMainContractorPhaseStatus();
      const payload = {
        status: AgreeTermsConsumerDisclosure.PENDING,
        totalEstimateCost:
          totalSubTotal.value + totalVat.value + serviceCharges.value,
      };
      const response =
        (await projectEstimationService.submitStatusWithEstimateUpdate(
          props.project?.id,
          formData.value?.id,
          payload
        )) as any;

      if (response) {
        if (props.project?.projectMetaData !== null) {
          await store.dispatch(`${PROJECT_STORE}/updateRaiseProjectStatus`, {
            projectId: props.project?.id,
            data: {
              status: ProjectMetaDataStatusEnum.PENDING,
            },
          });
          await store.dispatch(
            `${JOB_TEMPLATE_STORE}/setLockTemplateModifiedChecker`,
            true
          );
        }
      }
      setTimeout(() => {
        finalEstimateLoadingModal.step = 1;
      }, 1500);
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

const updateMainContractorPhaseStatus = async () => {
  estimateForm.value.projectStages.forEach(async (phase: any) => {
    if (
      phase?.workStation?.id === activeUserWorkstation.value?.id &&
      !phase?.assignPhaseWorkStation?.length &&
      !phase.phaseStatus
    ) {
      await projectEstimationService.acceptEstimatePhase(phase.id, {
        accptedData: [phase?.workStation?.id],
      });
    }
  });
};
const onFinalEstimateSubmitLoadingCancel = () => {
  finalEstimateLoadingModal.visible = false;
  finalEstimateLoadingModal.step = 0;
  let showSiteVisitRequest = false;
  if (
    depositModalData.value &&
    [
      EstimateTypeForDeposit.Variable_Cost_Estimation,
      EstimateTypeForDeposit.Fixed_Cost_Estimation,
    ].includes(depositModalData.value.depositType)
  ) {
    showSiteVisitRequest = true;
  }
  emits("onFinalSave", { showSiteVisitRequest });
};
</script>
<style lang="scss" scoped></style>
