import { ProjectState } from "@/core/models/project";

export default {
  projectDetails: (state: ProjectState) => state.projectDetails,
  projectAttachments: (state: any) => state.projectAttachments,
  projectSpecialisms: (state: any) => state.projectSpecialisms,
  invitedContractors: (state: any) => state.invitedContractors,

  draftProjectList: (state: ProjectState) => state.draftProjectList,
  selectedProperties: (state: ProjectState) => state.selectedProperties,
  getPropertyListState: (state: ProjectState) => state.propertyListState,
  getProjectRedirectTabName: (state: ProjectState) =>
    state.projectRedirectTabName,
  getProjectEstimateDataWithEscrow: (state: any) =>
    state.projectEstimateWithEscrow,
  historicalProjectForm: (state: any) => state.historicalProjectForm,
  getProjectEstimateEscrowBalance: (state: any) =>
    state.projectEstimateEscrowBalance,
  getProjectRequestedList: (state: any) => state.projectRequestedList,
  getProjectStatusCountsList: (state: any) => state.projectStatusCountsList,
};
