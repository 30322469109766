import { WorkStation } from "@/core/models/workstation";
import state from "./state";
export default {
  setWsModal(state: WorkStation, data: any) {
    state.ws_modal = data;
  },
  setActiveUserWorkstation(state: WorkStation, value: any) {
    state.activeUserWorkstation = value;
  },
  setUserWorkstations(state: WorkStation, value: Array<any>) {
    state.userWorkstations = value;
  },
  setPropertyOwnerUserWorkstations(state: WorkStation, value: Array<any>) {
    state.userWorkstations = value;
  },
  setUserWorkstationMembers(
    state: WorkStation,
    userWorkstationMembers: Array<any>
  ) {
    state.userWorkstationMembers = userWorkstationMembers;
  },
  setPropertyOwnerWorkStationModelState(state: WorkStation, data: boolean) {
    state.isShowCreatePropertyOwnerWorkStationModal = data;
  },
  setHomeownersWorkStationState(state: WorkStation, data: boolean) {
    state.isShowHomeownersWorkStation = data;
  },
  setActiveHomeownersWorkStationModal(state: WorkStation, activeIndex: number) {
    state.activeHomeownersWorkStationModal = activeIndex;
  },
  setLandlordsWorkStationState(state: WorkStation, data: boolean) {
    state.isShowLandlordsWorkStation = data;
  },
  setActiveLandlordsWorkStationModal(state: WorkStation, activeIndex: number) {
    state.activeLandlordsWorkStationModal = activeIndex;
  },
  setOccupiersWorkStationState(state: WorkStation, data: boolean) {
    state.isShowOccupiersWorkStation = data;
  },
  setActiveOccupiersWorkStationModal(state: WorkStation, activeIndex: number) {
    state.activeOccupiersWorkStationModal = activeIndex;
  },
  setHomeownersWelcomeState(state: WorkStation, data: boolean) {
    state.isShowHomeownersWelcome = data;
  },
  setActiveHomeownersWelcomeModal(state: WorkStation, activeIndex: number) {
    state.activeHomeownersWelcomeModal = activeIndex;
  },
  setWorkStationSiltVerificationStatus(state: WorkStation, value: any) {
    state.WorkStationSiltVerificationStatus = value;
  },
  setActiveUserWorkstationDescription(state: WorkStation, value: any) {
    state.activeUserWorkstationDescription = value;
  },
  setUserOnlineWorkStations: (state: WorkStation, user: any) => {
    const onlineUser = state.allOnlineWorkStations.find(
      (data) => data.id === user.id
    );
    if (onlineUser) {
      onlineUser.userWorkstations = user.userWorkstations;
      // onlineUser
    } else {
      state.allOnlineWorkStations.unshift(user);
    }
  },

  removeUserOnlineWorkStations: (state: WorkStation, member: any) => {
    state.allOnlineWorkStations = state.allOnlineWorkStations.filter(
      (user) => user.id !== member?.id
    );
  },

  setLoadingWorkstation(
    state: { loadingWorkstation: boolean },
    value: boolean
  ) {
    state.loadingWorkstation = value;
  },

  setAssignWorkStationData(state: any, payload: any) {
    state.assignWorkStationData = payload;
    console.log("setAssignWorkStationData", state.assignWorkStationData);
  },
};
