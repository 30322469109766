<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
  >
    <Loader :show="isLoading" />
  </div>
  <div v-if="!isLoading && userSpecialismList?.length">
    <PendingVerifyNotice
      v-if="
        userSkillVerificationStatus?.userVerificationStatus ===
          UserVerificationStatusEnum.PENDING_VERIFICATION &&
        isWorkStationVerified &&
        !isUserProprtyOwner
      "
      content="New services are being verified. Use existing ones."
    />

    <TradeWelcomeBoard
      v-if="!isUserProprtyOwner"
      title="Trades Services"
      description="Explore and offer a range of trade services to connect with property owners."
      actionTitle="Add New Trade Services "
      @onClickAction="onClickToggleSpecialismForm"
    />

    <div class="trade__specialism__wrapper md:!tw-pb-[4rem] md:!tw-box-border">
      <div class="trade__specialism-row">
        <v-card
          class="trade-specialism-card"
          v-for="specialism in userSpecialismList"
          :key="specialism?.id"
        >
          <div class="itemlist_card">
            <div class="itemlist_card__header">
              <div
                class="itemlist_card__action"
                v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
              >
                <v-icon
                  icon="mdi-pencil"
                  color="#80829F"
                  class="card__edit"
                  @click="editSpecialism(specialism)"
                ></v-icon>
                <v-icon
                  icon="mdi-delete"
                  @click="onDeleteItem(specialism?.id)"
                  color="#80829F"
                  class="card__delete"
                ></v-icon>
              </div>

              <div class="title">
                <v-icon>
                  <img
                    v-if="specialism?.category?.iconName"
                    :src="
                      require('@/assets/category-icons/' +
                        specialism?.category?.iconName +
                        '.svg')
                    "
                    alt="Image"
                  />
                </v-icon>
                <div class="specialism__text">
                  <h5>
                    {{ specialism?.label }}
                  </h5>
                  <p>
                    Added:
                    {{ new Date(specialism?.dateCreated).toLocaleDateString() }}
                  </p>
                </div>
              </div>
            </div>
            <div class="expertise__wrapper">
              <div class="expertise__text">
                <h5>Expertise</h5>
                <div class="expertise__tags">
                  <div
                    class="confirmed__tags"
                    v-for="subCategorie in specialism?.subCategories?.slice(
                      0,
                      2
                    )"
                    :key="subCategorie?.id"
                  >
                    <v-icon icon="mdi-check-circle" color="orange" />
                    <span> {{ subCategorie.label }}</span>
                  </div>
                  <div
                    class="more_tags"
                    v-if="specialism?.subCategories?.length > 2"
                  >
                    <span> +{{ specialism?.subCategories?.length - 2 }}</span>
                    More <v-icon icon="mdi-chevron-right" />
                  </div>
                </div>
              </div>
            </div>
            <div class="members_wrapper">
              <div class="expertise__text">
                <h5>
                  Members <span>({{ specialism?.assignedUsers?.length }})</span>
                </h5>
                <div class="members__list">
                  <div
                    class="members__list__info"
                    v-for="assignedMember in specialism?.assignedUsers?.slice(
                      0,
                      4
                    )"
                    :key="assignedMember?.id"
                  >
                    <div class="members_img">
                      <img
                        v-if="assignedMember?.profileImageURL"
                        :src="assignedMember?.profileImageURL"
                        alt=""
                      />
                      <UserProfileLogo
                        v-else
                        :userName="`${assignedMember?.firstName} ${assignedMember?.lastName}`"
                      />
                    </div>
                    <h6>
                      {{ assignedMember?.firstName }}
                      {{ assignedMember?.lastName }}
                    </h6>
                  </div>

                  <div
                    class="members__list__more"
                    v-if="specialism?.assignedUsers?.length > 4"
                  >
                    +{{ specialism?.assignedUsers?.length - 4 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && !userSpecialismList?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img">
        <img
          :src="require('../../../assets/images/trade-services-empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>
      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any services yet!</h4>
        <p>
          It looks like you haven't added any services yet. Add your offerings
          to connect with property owners and showcase your skills.
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any services yet!</h4>
        <p>It looks like you haven't added any services to your profile yet.</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onClickToggleSpecialismForm"
          v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
          class="button button-purple"
          width="100%"
          >add new trade Services</v-btn
        >
      </div>
    </div>
  </div>
  <SpecialismEditModal
    v-if="isShowEditSpecialismModal"
    @onClickCancel="onClickCloseEditModal"
    :userSpecialismData="userSpecialismData"
  />
  <SpecialismMultiSelectModel
    v-if="isShowSpecialismModal"
    @onClickAddNewTradeSpecialism="onClickAddNewTradeSpecialism"
    @onClickCancel="closeSpecialismMultiSelectModel"
  />
  <AddNewTradeSpecialism
    v-if="isShowAddNewTradeSpecialism"
    @onClickCancelButton="closeAddNewTradeSpecialism"
  />
  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    label="You're about to delete your service."
    @on-close="onCloseConfirmDelete"
    @on-delete="onClickDeleteSpecialism"
    :loading="deleteLoader"
  ></ConfirmDeleteModal>
</template>

<script lang="ts">
import { computed, ComputedRef, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import SpecialismEditModal from "@/modules/Specialism/components/SpecialismEditModal.vue";
import { WORKSTATION } from "@/store/modules/workstation";

import SpecialismMultiSelectModel from "@/modules/Specialism/components/SpecialismMultiSelectModel.vue";
import AddNewTradeSpecialism from "@/modules/Specialism/components/AddNewTradeSpecialism.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { getImageStringToImageURL } from "@/core/utils/common";
import userService from "@/core/services/user.service";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { useRoute } from "vue-router";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import Loader from "@/core/components/common/Loader.vue";
import TradeWelcomeBoard from "./cards/TradeWelcomeBoard.vue";
import PendingVerifyNotice from "./cards/PendingVerifyNotice.vue";

export default {
  components: {
    SpecialismMultiSelectModel,
    AddNewTradeSpecialism,
    TradeWelcomeBoard,
    SpecialismEditModal,
    UserProfileLogo,
    ConfirmDeleteModal,
    Loader,
    PendingVerifyNotice,
  },
  setup() {
    const dashBoardWelcomeButton = ref({
      text: "CREATE A Trades WORKSTATION",
      class: ["button", "button-purple-border"],
    }) as any;
    const store = useStore();
    const route = useRoute();
    const isShowSpecialismModal = ref(false);
    const isShowAddNewTradeSpecialism = ref(false);
    const isShowEditSpecialismModal = ref(false);
    const userSpecialismData = ref(null) as any;
    const userSpecialismList = ref([]) as any;
    const deleteLoader = ref(false);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    const isOpenConfirmDelete = ref(false);
    const isLoading = ref(false);
    const getIsOpenTradePassportasViewMode = computed(
      () =>
        store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
    );
    const isUserProprtyOwner = computed(
      () =>
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
        getIsOpenTradePassportasViewMode.value
    );
    const setMemberProfile = async () => {
      userSpecialismList.value = userSpecialisms.value;
      for (const specialism of userSpecialismList.value) {
        specialism["label"] = specialism?.category.poLabel
          ? specialism?.category.tpLabel
          : "";
        for (const user of specialism.assignedUsers) {
          user.profileImageURL = await getImageStringToImageURL(
            user?.userPublicProfile?.profileImage
          );
        }
      }
    };

    const onClickDeleteSpecialism = async () => {
      try {
        deleteLoader.value = true;
        const res = await userService.deleteSkill(
          user.value.id,
          confirmDeleteService.value as number
        );
        await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
        setMemberProfile();
      } catch (error) {
        console.log("delete specialism error", error);
      } finally {
        deleteLoader.value = false;
        onCloseConfirmDelete();
      }
    };

    const onClickToggleSpecialismForm = async () => {
      isShowSpecialismModal.value = !isShowSpecialismModal.value;
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const onClickAddNewTradeSpecialism = async () => {
      isShowSpecialismModal.value = false;
      isShowAddNewTradeSpecialism.value = true;
    };

    const closeSpecialismMultiSelectModel = async (
      isNewServiceAdded: any = false
    ) => {
      if (isNewServiceAdded) {
        userSkillVerificationStatus.value = await store.dispatch(
          `${USER_STORE}/getUserSkillVerificationStatus`,
          user.value.id
        );
      }
      isShowSpecialismModal.value = false;
      setMemberProfile();
    };

    const closeAddNewTradeSpecialism = () => {
      isShowSpecialismModal.value = true;
      isShowAddNewTradeSpecialism.value = false;
    };

    const editSpecialism = (specialism: any) => {
      userSpecialismData.value = specialism;
      isShowEditSpecialismModal.value = true;
    };
    const onClickCloseEditModal = () => {
      isShowEditSpecialismModal.value = false;
      setMemberProfile();
    };
    const contractorTradePassportWorkstationId = computed(
      () =>
        store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
        ]
    );

    const confirmDeleteService = ref<null | number>(null);
    const onDeleteItem = (specialismId: number) => {
      confirmDeleteService.value = specialismId;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteService.value = null;
      isOpenConfirmDelete.value = false;
    };

    const isWorkStationVerified = computed(() => {
      return (
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus === UserVerificationStatusEnum.VERIFIED
      );
    });

    const userSkillVerificationStatus = ref();
    onMounted(async () => {
      try {
        isLoading.value = true;
        if (isUserProprtyOwner.value) {
          await store.dispatch(`${USER_STORE}/getContractorSkills`, {
            userId: user.value.id,
            workStationId: contractorTradePassportWorkstationId.value,
          });
        } else {
          await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
        }
        setMemberProfile();
        userSkillVerificationStatus.value = await store.dispatch(
          `${USER_STORE}/getUserSkillVerificationStatus`,
          user.value.id
        );
      } catch (error) {
        console.log("error while loading trade services", error);
      } finally {
        isLoading.value = false;
      }
    });
    return {
      dashBoardWelcomeButton,
      onClickToggleSpecialismForm,
      isShowSpecialismModal,
      formatDate,
      onClickAddNewTradeSpecialism,
      isShowAddNewTradeSpecialism,
      closeSpecialismMultiSelectModel,
      userSpecialisms,
      closeAddNewTradeSpecialism,
      onClickDeleteSpecialism,
      isShowEditSpecialismModal,
      onClickCloseEditModal,
      editSpecialism,
      userSpecialismData,
      getUserWorkStationFieldAccess,
      userSpecialismList,
      isOpenConfirmDelete,
      onDeleteItem,
      onCloseConfirmDelete,
      deleteLoader,
      isLoading,
      isUserProprtyOwner,
      userSkillVerificationStatus,
      UserVerificationStatusEnum,
      isWorkStationVerified,
      getIsOpenTradePassportasViewMode,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
@import "@/modules/onboarding/styles/onboarding.scss";
</style>
