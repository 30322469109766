<template>
  <Card class="!tw-max-h-[unset] !tw-min-w-[260px] !tw-p-4 tw-box-border">
    <template #content>
      <main class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
        <section
          v-for="(item, index) in menu"
          :key="index"
          @click="setSelected(item.name)"
          class="tw-flex tw-items-center tw-w-full tw-justify-between tw-gap-2 tw-py-2 tw-px-3 pw-box-border tw-rounded-md hover:tw-bg-[#FFF6E5] tw-cursor-pointer"
        >
          <Text
            class="hover:!tw-text-[#FFA500] !tw-capitalize"
            variant="p"
            textWeight="400"
            :textColor="isSelected(item.name) ? '#FFA500' : '#0C0F4A'"
            >{{ item.name }}</Text
          >
          <v-icon
            icon="mdi-check"
            color="#FFA500"
            v-if="isSelected(item.name)"
          ></v-icon>
        </section>
      </main>
    </template>
  </Card>
</template>
<script setup>
import { onMounted, reactive, computed, ref } from "vue";
import { useStore } from "vuex";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import documentConstants from "@/modules/documents/contants";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const ALL = "all";
const FOLDERS = "folders";
const FILES = "files";
const SHARED = "shared with me";
const emits = defineEmits(["set-selected-sort"]);
const props = defineProps(["selectedSortMenu"]);

const store = useStore();

const hasParentParam = null;
const documentPromises = [];

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};

const setFileList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFileList`, params);
};

const state = reactive({
  selectedSort: ALL,
});

const menu = reactive([
  {
    name: ALL,
  },
  {
    name: FOLDERS,
  },
  {
    name: FILES,
  },
  {
    name: SHARED,
  },
]);

const isSelected = (name) => {
  return name === state.selectedSort;
};

const getUserDocumentCalculatedSize = async (params) => {
  return await store.dispatch(
    `${DOCUMENTS_STORE}/getUserDocumentCalculatedSize`,
    params
  );
};

const fetchUserDocuments = async (options) => {
  const params = {
    userId: user.value?.id,
    options,
  };
  return await store.dispatch(`${DOCUMENTS_STORE}/getUserDocuments`, params);
};

const updateFileList = (userDocuments, hasParentParam) => {
  const fileList = userDocuments.filter(
    (userDocument) =>
      !userDocument.isDir && userDocument.parentId === hasParentParam
  );
  return fileList.map((file) => ({
    ...file,
    name: file.originalName,
    attachment: file.name,
  }));
};

const updateFolderList = async (userDocuments, hasParentParam) => {
  const folderList = userDocuments.filter(
    (userDocument) =>
      userDocument.isDir === true && userDocument.parentId === hasParentParam
  );

  const documentPromises = folderList.map((folderItem) =>
    getUserDocumentCalculatedSize({
      userId: user.value?.id,
      userDocumentId: folderItem.id,
    })
  );

  const documentFolderSizes = await Promise.all(documentPromises);
  return folderList.map((item) => {
    const findFolder = documentFolderSizes.find((x) => x.id === item.id);
    return { ...item, ...findFolder };
  });
};

const setSelected = async (name) => {
  if (isPersonalAccountWorkStation.value)
    return emits("set-selected-sort", name);
  try {
    let userDocuments = [];
    emits("set-selected-sort", name);
    switch (name) {
      case documentConstants.FOLDERS:
        userDocuments = await fetchUserDocuments({ isDir: "1" });
        state.currentFolderList = await updateFolderList(
          userDocuments,
          hasParentParam
        );
        state.currentFileList = updateFileList(userDocuments, hasParentParam);
        setFolderList(state.currentFolderList);
        setFileList([]);
        break;

      case documentConstants.FILES:
        userDocuments = await fetchUserDocuments({ isDir: "0" });
        setFolderList([]);
        setFileList(userDocuments);
        break;

      case documentConstants.SHARED:
        userDocuments = await fetchUserDocuments({ sheredWithMe: "1" });
        setFolderList(userDocuments);
        setFileList([]);
        break;

      case documentConstants.ALL:
        userDocuments = await fetchUserDocuments({});
        state.currentFolderList = await updateFolderList(
          userDocuments,
          hasParentParam
        );
        state.currentFileList = updateFileList(userDocuments, hasParentParam);
        setFolderList(state.currentFolderList);
        setFileList(state.currentFileList);
        break;
    }
  } catch (e) {
    const formError = "Error getting list. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  }
};
const isPersonalAccountWorkStation = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name === "Default"
);

onMounted(() => {
  state.selectedSort = props.selectedSortMenu;
});
</script>
<style lang="scss" scoped></style>
