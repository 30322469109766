import { JobsState } from "@/core/models/jobs";

export default {
  getJobs: (state: JobsState) => state.jobs,
  getMyJobs: (state: JobsState) => state.myJobs,
  getDraftJobs: (state: JobsState) => state.draftJobs,
  getRejectedJobs: (state: JobsState) => state.rejectedJobs,
  getJobDetails: (state: JobsState) => state.jobDetails,
  getHistoricalJobs: (state: JobsState) => state.historicalJobs,
  getActiveHistoricalJob: (state: JobsState) => state.activeHistoricalJob,
  getVideoScheduleTime: (state: JobsState) => state.videoScheduleTime,
  getWorkFlowType: (state: any) => state.workFlowType,
  getJobStatusCountsList: (state: any) => state.jobStatusCountsList,
  getHistoricalDraftProjects: (state: any) => state.historicalDraftProjects,
  getCurrentHistoricalDraftProject: (state: any) => state.currentDraftProject,
  getJobRedirectTabName: (state: any) => state.jobRedirectTabName,
  getJobEmergencyCallOutDetails: (state: any) =>
    state.jobEmergencyCallOutDetails,
};
