import { Commit } from "vuex";
import {
  AuthLogin,
  VerifyEmail,
  AuthRegistration,
  VerifyCode,
  UpdateUserPasswordRequest,
} from "@/core/models/authentication";
import AuthService from "@/core/services/authentication.service";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "@/core/utils/common";
import { AUTH_SESSION_EXPIRY, AUTH_TOKEN } from "@/core/constants";
import PusherPlugin from "@/core/utils/pusher";
import app from "@/main";
import { API_URL, PUSHER_API_KEY, PUSHER_CLUSTER } from "@/config";

export default {
  async login({ commit }: { commit: Commit }, payload: AuthLogin) {
    const { username, password } = payload;
    return AuthService.login(username, password).then((response: any) => {
      const token = response.IdToken;
      if (token) {
        setLocalStorageWithExpiry(token, AUTH_TOKEN, AUTH_SESSION_EXPIRY);
        // Initial Pusher
        app.use(PusherPlugin, {
          apiKey: PUSHER_API_KEY,
          options: {
            cluster: PUSHER_CLUSTER,
            authEndpoint: `${API_URL}user-notifications/authenticate`,
            auth: {
              headers: {
                Authorization: `Bearer ${getLocalStorageWithExpiry(
                  AUTH_TOKEN
                )}`,
              },
            },
          },
        });
        return response;
      }
      return null;
    });
  },
  async verifyEmail({ commit }: { commit: Commit }, payload: VerifyEmail) {
    const { email } = payload;
    return AuthService.verifyEmail(email).then((response) => {
      return response;
    });
  },
  async registration(
    { commit }: { commit: Commit },
    payload: AuthRegistration
  ) {
    return AuthService.registration(payload).then((response) => {
      return response;
    });
  },
  async verifyCode({ commit }: { commit: Commit }, payload: VerifyCode) {
    return AuthService.verifyCode(payload).then((response) => {
      return response;
    });
  },
  async resendEmailOTP({ commit }: { commit: Commit }, email: string) {
    if (!email) return;
    return await AuthService.resendOTP(email);
  },

  async resetPassword({ commit }: { commit: Commit }, payload: VerifyEmail) {
    return await AuthService.forgotPassword(payload);
  },

  async verifyResetPasswordToken(
    { commit }: { commit: Commit },
    token: string
  ) {
    const requestVerifyResetPasswordToken =
      await AuthService.verifyResetPasswordToken(token);

    if (requestVerifyResetPasswordToken) {
      const { id, email }: any = requestVerifyResetPasswordToken;
      commit("setForgotPasswordTempUser", { userId: id, email });
    }

    return requestVerifyResetPasswordToken;
  },

  async changePassword(
    { getters, commit }: { getters: any; commit: Commit },
    payload: { password: string; email: string; userId: number }
  ) {
    const formData = {
      password: payload.password,
      userId: payload.userId
        ? payload.userId
        : getters.forgotPasswordTempUser.userId,
      email: payload.email
        ? payload.email
        : getters.forgotPasswordTempUser.email,
    };
    const requestChangePassword = await AuthService.changePassword(formData);

    if (requestChangePassword) {
      commit("setForgotPasswordTempUser", { userId: 0, email: "" });
    }

    return requestChangePassword;
  },
  async logOut(
    { getters, commit }: { getters: any; commit: Commit },
    payload: { userId: number }
  ) {
    await AuthService.logOut(payload.userId);
  },
};
