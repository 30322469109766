<template>
  <main class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full">
    <el-form
      class="tw-flex tw-w-full tw-max-w-full tw-gap-8 tw-items-center md:tw-flex-col md:tw-gap-4"
      :model="
        estimateForm.projectStages[phaseIndex].projectStageTasks[taskIndex]
      "
    >
      <el-form-item
        class="tw-w-[60%] tw-max-w-full md:tw-w-full"
        :class="{ 'is-error': !!subTaskErrors.name }"
      >
        <NoBgInput
          v-model="
            estimateForm.projectStages[phaseIndex].projectStageTasks[taskIndex]
              .name
          "
          placeholder="Task Name e.g Wall Painting"
          @change="updateLabourPhaseTask"
        />
      </el-form-item>
      <div class="tw-flex tw-gap-8 md:tw-w-full md:tw-max-w-full tw-w-[40%]">
        <el-form-item class="tw-w-[40%] tw-max-w-full">
          <div
            class="tw-flex tw-flex-row tw-justify-center tw-items-center tw-w-[100%]"
          >
            <input
              class="tw-w-[100%] tw-text-xs tw-font-medium tw-leading-8 tw-text-rgba(12, 15, 74, 0.5)"
              min="1"
              type="number"
              :class="{
                errorInput: !!subTaskErrors.duration,
              }"
              v-model="
                estimateForm.projectStages[phaseIndex].projectStageTasks[
                  taskIndex
                ].duration
              "
              @input="handleTotalDuration($event)"
            />

            <span class="tw-text-xs tw-leading-8 tw-text-rgba(12, 15, 74, 0.5)">
              Days
            </span>
            <NoBgSelect
              class="tw-max-w-[15px]"
              v-model="
                estimateForm.projectStages[phaseIndex].projectStageTasks[
                  taskIndex
                ].durationType
              "
              @change="updateLabourPhaseTask"
            >
              <template #option>
                <el-option
                  v-for="item in durationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </template>
            </NoBgSelect>
          </div>
        </el-form-item>

        <el-form-item
          class="tw-w-[60%] tw-max-w-full"
          :class="{ 'is-error': !!subTaskErrors.taskCost }"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <Text variant="p">£</Text>

            <NoBgInput
              @keypress="
                numChecker(
                  $event,
                  estimateForm.projectStages[phaseIndex].projectStageTasks[
                    taskIndex
                  ].taskCost
                )
              "
              @input="handleSubTotalTask($event)"
              placeholder="Task subtotal"
              v-model="
                estimateForm.projectStages[phaseIndex].projectStageTasks[
                  taskIndex
                ].taskCost
              "
              :formatter="
                (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :parser="(value) => value.replace(/^0+(?=\d)|\$|,/g, '')"
            />
          </div>
        </el-form-item>
      </div>
    </el-form>

    <section
      class="tw-flex tw-items-center tw-justify-between tw-w-full md:!tw-items-start tw-gap-4"
    >
      <!-- <AddPropertyForm /> -->
      <AddressItem
        :showAddAction="true"
        :phaseIndex="phaseIndex"
        :taskIndex="taskIndex"
        @on-save-project-properties="saveProperties"
      />

      <Button
        @click="toggleShowDeleteModal"
        label="Delete Task"
        variant="custom"
        :isCapitalize="true"
        class="!tw-w-[auto] !tw-p-0"
        :ripple="false"
        fontSize="12px"
        fontWeight="400"
        letterSpacing="0"
        activeBgColor="#FFF"
        activeFontColor="#0C0F4A"
        width="auto"
      >
        <template #prefix>
          <TrashIcon />
        </template>
      </Button>

      <ConfirmPhaseTaskDeleteModal
        v-if="isShowDeleteModal"
        @on-close="toggleShowDeleteModal"
        @on-confirm="onDeleteTask"
      />
    </section>
  </main>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import NoBgInput from "@/core/components/ui/inputs/NoBgInput.vue";
import NoBgSelect from "@/core/components/ui/inputs/NoBgSelect.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import AddressItem from "@/modules/project/components/common/AddressItem.vue";
import PlusFilledYellowIcon from "@/core/components/icons/PlusFilledYellowIcon.vue";
import AddPropertyForm from "@/modules/jobs/components/estimates/form/AddPropertyForm.vue";
import { isNumber } from "@/core/helpers/number.helper";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import estimate from "@/core/constants/estimate";
import moment from "moment";
import { ElMessage, stepProps } from "element-plus";
import ConfirmPhaseTaskDeleteModal from "../../modal/ConfirmPhaseTaskDeleteModal.vue";

const store = useStore();
const MAX_LIMIT = 99999999;
const emits = defineEmits(["on-update-labour-task"]);
const durationOptions = [
  {
    value: 1,
    label: "Days",
  },
  // {
  //   value: 2,
  //   label: "Weeks",
  // },
];

const props = defineProps(["phaseIndex", "taskIndex", "task"]);
const isShowDeleteModal = ref(false);
const toggleShowDeleteModal = () => {
  isShowDeleteModal.value = !isShowDeleteModal.value;
};
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);

const numChecker = (event, value) => {
  return isNumber(event, value);
};

const onDeleteTask = async () => {
  if (!isFormReadOnly.value) {
    const estimatePhaseTask = estimateForm.value.projectStages[
      props.phaseIndex
    ].projectStageTasks.find((data, idx) => idx === props.taskIndex);

    try {
      if (estimatePhaseTask.id) {
        await store.dispatch(
          `${JOB_TEMPLATE_STORE}/removeEstimationPhaseTask`,
          {
            estimatePhaseTaskId: estimatePhaseTask.id,
          }
        );

        estimateForm.value.projectStages[props.phaseIndex].projectStageTasks =
          estimateForm.value.projectStages[
            props.phaseIndex
          ].projectStageTasks.filter((x, xi) => xi !== props.taskIndex);
      }
    } catch (error) {
      console.log();
    } finally {
      toggleShowDeleteModal();
    }
  }
};

const saveProperties = (payload) => {
  if (payload && payload.length) {
    updateLabourPhaseTask();
  }
};
const calculateEndDate = (durationType, totalDuration, startDate) => {
  let duration = parseInt(totalDuration);
  if (duration < 1 || !startDate) return;

  const endDate = moment(new Date(startDate))
    .add(totalDuration, durationType === 1 ? "days" : "weeks")
    .format("YYYY-MM-DD");

  estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
    props.taskIndex
  ].endDate = endDate;
};
const updateLabourPhaseTask = async () => {
  estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
    props.taskIndex
  ].startDate = estimateForm.value.projectStages[props.phaseIndex].startDate;

  const { name, duration, endDate, startDate, taskCost, durationType } =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.taskIndex
    ];

  if (taskCost) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.taskIndex,
      field: "taskCost",
    });
  }
  if (duration) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.taskIndex,
      field: "duration",
    });
  }
  if (name) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.taskIndex,
      field: "name",
    });
  }

  calculateEndDate(durationType, duration, startDate);
  if (!durationCalculate.value) emits("on-update-labour-task");
};

const handleSubTotalTask = async (value) => {
  const phase = estimateForm.value.projectStages[props.phaseIndex];
  if (!value) {
    phase.projectStageTasks[props.taskIndex].taskCost = "0";
  }

  let currentTotal = phase.projectStageTasks.reduce((sum, task, index) => {
    return sum + (parseFloat(task.taskCost) || 0);
  }, 0);

  store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseSubTotal`, {
    phaseIndex: props.phaseIndex,
    newValue: currentTotal,
  });

  let errorStatus = true;
  if (!validCost.value) {
    updateLabourPhaseTask();
    errorStatus = false;
  } else {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/setFormError`, {
      field: "validationError",
      value: errorStatus,
    });
  }
};

const handleTotalDuration = async (event) => {
  const value = event.target.value;
  const phase = estimateForm.value.projectStages[props.phaseIndex];
  const totalDuration = phase.totalDuration;
  if (!value) {
    phase.projectStageTasks[props.taskIndex].duration = "0";
  }

  if (+totalDuration < +value) {
    ElMessage({
      message:
        "Your task durations are too long. Please keep the total within the specified limit.",
      type: "error",
    });
  } else {
    updateLabourPhaseTask();
  }
};

const validCost = computed(() => {
  const cost =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.taskIndex
    ].taskCost;
  if (cost > MAX_LIMIT) {
    return true;
  }
  return false;
});

const durationCalculate = computed(() => {
  let mainTaskDays = 0;
  const phase = estimateForm.value.projectStages[props.phaseIndex];
  let inputDuration = phase.projectStageTasks[props.taskIndex].duration;
  if (phase.durationType === estimate.WEEKS) {
    mainTaskDays = phase.totalDuration * 7;
  } else {
    mainTaskDays = phase.totalDuration;
  }
  if (parseInt(inputDuration) > mainTaskDays) {
    return true;
  } else {
    return false;
  }
});

const subTaskErrors = computed(() => {
  const errors =
    store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
  if (props.taskIndex !== undefined) {
    return errors[props.phaseIndex]?.subErrors?.[props.taskIndex] || {};
  }
  return {};
});
</script>
<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}
input {
  outline: none;
}
.errorInput {
  border: 1px solid red;
}
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}
:deep(.v-btn:hover) > .v-btn__overlay {
  opacity: 0;
}
:deep(.el-select) {
  .el-select__selection {
    display: none;
  }

  .is-error input,
  .is-error select {
    border-color: red;
  }
}
</style>
