<template>
  <div class="revenue__widget">
    <AnalyticsWidgetLayout
      class="revenue__card"
      title="Revenue Overview"
      :hideToolTip="true"
      headerIcon="revenueOverview"
      :borderBottom="true"
    >
      <template #content>
        <div class="revenue_overview__content">
          <div
            id="revenueOverviewChart"
            class="revenue_left__block"
            v-show="hasRevenueData"
          ></div>
          <div
            v-show="!hasRevenueData"
            id="revenueOverviewChartEmptyState"
            class="revenue_left__block"
          ></div>
          <div class="revenue_right__block">
            <div class="iknowa__revenue">
              <div class="top__section">
                <span v-if="hasRevenueData"></span>
                <span v-else class="empty-span-iknowa-revenue"></span>
                <p>iknowa Revenue</p>
              </div>
              <h5>
                {{
                  CurrencyFilter.formatToCurrency(
                    revenueData?.iknowaRevenue.toLocaleString()
                  )
                }}
              </h5>
            </div>

            <div class="direct__revenue">
              <div class="top__section">
                <span v-if="hasRevenueData"></span>
                <span v-else class="empty-span-direct-revenue"></span>
                <p>Direct Revenue</p>
              </div>
              <h5>
                {{
                  CurrencyFilter.formatToCurrency(
                    revenueData?.directRevenue.toLocaleString()
                  )
                }}
              </h5>
            </div>
          </div>
        </div>
        <div class="revenue_overview__footer">
          <div class="custom__tag">
            <div class="icon">
              <img
                src="../../../assets/icons/house.svg
                "
              />
            </div>
            <span>Residential</span>
          </div>
          <div class="custom__tag">
            <div class="icon">
              <img src="../../../assets/icons/user.svg" alt="" />
            </div>
            <span>Commercial</span>
          </div>
        </div>
      </template>
    </AnalyticsWidgetLayout>
    <AnalyticsWidgetLayout
      class="revenue__card"
      title="Revenue Performance"
      :hideToolTip="true"
      headerIcon="revenuePerformance"
      :borderBottom="true"
      :isHeaderAction="true"
    >
      <template #content>
        <!-- <div
          style="width: 100%; height: 415px"
          id="revenuePerformanceChart"
        ></div> -->
        <div
          style="width: 100%; height: 415px"
          id="revenuePerformanceChartEmptyState"
        ></div>
      </template>
      <template #header-action>
        <v-select
          v-model="selectedFilter"
          :items="filterItems"
          item-title="range"
          item-value="value"
          variant="outlined"
          placeholder="Quarterly"
          bg-color="#fff"
          class="input_field !tw-w-[140px]"
          type="select"
        ></v-select>
      </template>
    </AnalyticsWidgetLayout>
  </div>
</template>

<script lang="ts" setup>
import AnalyticsWidgetLayout from "@/core/components/slots/AnalyticsWidgetLayout.vue";
import { ANALYTICS_STORE } from "@/store/modules/analytics";
import Highcharts from "highcharts";
import { computed, onMounted, ref, watch, onBeforeMount } from "vue";
import { useStore } from "vuex";
import CurrencyFilter from "@/filters/currency.filter";
import arrowUpIcon from "@/assets/icons/arrow-up-icon.svg";
import arrowDownIcon from "@/assets/icons/arrow-down-icon.svg";
import CommonLoader from "@/core/components/CommonLoader.vue";

const store = useStore();
const revenuePerformanceChart = ref<any>(null);
const revenuePerformanceChartEmptyState = ref<any>(null);

const revenueOverviewChart = ref<any>(null);
const revenueOverviewChartEmptyState = ref<any>(null);

const loadingRevenuePerformance = ref(false);

const revenueOverviewChartData = computed(
  () => store.getters[`${ANALYTICS_STORE}/getRevenueOverview`]
);

const revenueData = computed(
  () => store.getters[`${ANALYTICS_STORE}/getRevenueData`]
);

const hasRevenueData = computed(() => {
  const { iknowaRevenueByPropertyCategory, directRevenueByPropertyCategory } =
    revenueData.value;

  return (
    iknowaRevenueByPropertyCategory.residential > 0 ||
    iknowaRevenueByPropertyCategory.commercial > 0 ||
    directRevenueByPropertyCategory.residential > 0 ||
    directRevenueByPropertyCategory.commercial > 0
  );
});

const revenuePerformanceChartData = computed(
  () => store.getters[`${ANALYTICS_STORE}/getRevenuePerformance`]
);

const revenuePerformanceData = computed(
  () => store.getters[`${ANALYTICS_STORE}/getRevenuePerformanceData`]
);

const xAxisCategories = computed(() => {
  if (revenuePerformanceData.value) {
    if (selectedFilter.value === "last7days") {
      const range = revenuePerformanceData.value.map((data: any) => {
        return data.day;
      });

      return range;
    }
    if (selectedFilter.value === "last4weeks") {
      const range = revenuePerformanceData.value.map((data: any) => {
        return data.Week;
      });

      return range;
    }
    if (
      selectedFilter.value === "last3months" ||
      selectedFilter.value === "last12months" ||
      selectedFilter.value === "alltime"
    ) {
      const range = revenuePerformanceData.value.map((data: any) => {
        return data.month;
      });

      return range;
    }
    if (
      selectedFilter.value === "yeartodate" ||
      selectedFilter.value === "monthtodate" ||
      selectedFilter.value === "quartertodate"
    ) {
      return [revenuePerformanceData.value.dateRange];
    }
  }
  return null;
});

const yAxisIknowaRevenuePerformanceData = computed(() => {
  if (revenuePerformanceData.value) {
    if (
      selectedFilter.value === "yeartodate" ||
      selectedFilter.value === "monthtodate" ||
      selectedFilter.value === "quartertodate"
    ) {
      return [revenuePerformanceData.value.iknowa];
    } else {
      const value = revenuePerformanceData.value.map((data: any) => {
        return data.iknowa;
      });

      return value;
    }
  }
  return null;
});

const yAxisDirectRevenuePerformanceData = computed(() => {
  if (revenuePerformanceData.value) {
    if (
      selectedFilter.value === "yeartodate" ||
      selectedFilter.value === "monthtodate" ||
      selectedFilter.value === "quartertodate"
    ) {
      return [revenuePerformanceData.value.direct];
    } else {
      const value = revenuePerformanceData.value.map((data: any) => {
        return data.direct;
      });

      return value;
    }
  }
  return null;
});
// const categories = ref(["Jan", "Feb", "Mar", "Apr", "May", "Jun"]); //["Week 1", "Week 2", "Week 3", "Week 4"];
const filterItems = ref([
  { range: "Last 7 Days", value: "last7days" },
  { range: "Last 4 Weeks", value: "last4weeks" },
  { range: "Last 3 Months", value: "last3months" },
  { range: "Last 12 Months", value: "last12months" },
  { range: "Month To date", value: "monthtodate" },
  { range: "Quarter to date", value: "quartertodate" },
  { range: "Year to date", value: "yeartodate" },
  { range: "All time", value: "alltime" },
]);
const selectedFilter = ref("last3months");

const revenueOverviewChartOption = computed(() => {
  return {
    chart: {
      type: "pie",
    },

    title: {
      text: "",
    },
    tooltip: {
      useHTML: true, // Enable HTML for custom formatting
      pointFormat: "<b>{point.name}</b>: £{point.y}<br/>", // Custom format
      style: {
        color: "#333333", // Tooltip text color
        fontSize: "14px",
        fontWeight: "500", // Tooltip text size
      },
    },
    plotOptions: {
      pie: {
        innerSize: "75%", // This creates the donut effect
        slicedOffset: 20,
        depth: 45,

        startAngle: -90, // Start angle to create a semi-circle
        endAngle: 90, // End angle to create a semi-circle
        dataLabels: {
          enabled: true,
          useHTML: true,
          distance: -15, // Distance from the pie slice
          formatter: function (): any {
            // Return custom HTML content
            return `<span >
                        <img src="${require(`@/assets/icons/${
                          this.point.name + ".svg"
                        }`)}" style="width: 20px; height: 20px; vertical-align: middle;" />
                    </span>`;
          },
          align: "right", // Align the label (can be 'left', 'center', 'right')
          verticalAlign: "middle",
        },
        borderRadius: 30,
      },
    },
    series: [
      {
        name: "",
        data: [
          {
            name: "Residential",
            y: revenueData.value?.iknowaRevenueByPropertyCategory.residential,
            color: "#4F55F0",
          },
          {
            name: "Commercial",
            y: revenueData.value?.iknowaRevenueByPropertyCategory.commercial,
            color: "#4F55F0",
          },
          {
            name: "Residential",
            y: revenueData.value?.directRevenueByPropertyCategory.residential,
            color: "#FFA500",
          },
          {
            name: "Commercial",
            y: revenueData.value?.directRevenueByPropertyCategory.commercial,
            color: "#FFA500",
          },
        ],
      },
    ],
    subtitle: {
      text: `<div><p>Total revenue</p><h5>${CurrencyFilter.formatToCurrency(
        revenueData.value?.totalRevenue.toLocaleString()
      )}</h5><span class='revenue_count'>${
        revenueData.value?.iknowaPercentage +
          revenueData.value?.directPercentage >
        0
          ? `<img src="${arrowUpIcon}" />`
          : revenueData.value?.iknowaPercentage +
              revenueData.value?.directPercentage <
            0
          ? `<img src="${arrowDownIcon}" />`
          : "" // No image if the percentage is 0
      } ${
        revenueData.value?.iknowaPercentage +
        revenueData.value?.directPercentage
      }% from last month</span></div>`,
      useHTML: true,
      verticalAlign: "middle",
      align: "center",
    },
  };
});

const revenueOverviewChartOptionEmptyState = computed(() => {
  return {
    chart: {
      type: "pie",
    },

    title: {
      text: "",
    },
    tooltip: {
      useHTML: true, // Enable HTML for custom formatting
      pointFormat: "<b>{point.name}</b>: £0<br/>", // Custom format
      style: {
        color: "#333333", // Tooltip text color
        fontSize: "14px",
        fontWeight: "500", // Tooltip text size
      },
    },
    plotOptions: {
      pie: {
        innerSize: "75%", // This creates the donut effect
        slicedOffset: 20,
        depth: 45,

        startAngle: -90, // Start angle to create a semi-circle
        endAngle: 90, // End angle to create a semi-circle
        dataLabels: {
          enabled: true,
          useHTML: true,
          distance: -15, // Distance from the pie slice
          formatter: function (): any {
            // Return custom HTML content
            return `<span >
                        <img src="${require(`@/assets/icons/${
                          this.point.name + ".svg"
                        }`)}" style="width: 20px; height: 20px; vertical-align: middle;" />
                    </span>`;
          },
          align: "right", // Align the label (can be 'left', 'center', 'right')
          verticalAlign: "middle",
        },
        borderRadius: 30,
      },
    },
    series: [
      {
        name: "",
        data: [
          {
            name: "Commercial",
            y: 50,
            color: "#AEAEAE",
          },
          {
            name: "Residential",
            y: 50,
            color: "#5F5F5F",
          },
        ],
      },
    ],
    subtitle: {
      text: `<div><p>Total revenue</p><h5>${CurrencyFilter.formatToCurrency(
        revenueData.value?.totalRevenue.toLocaleString()
      )}</h5><span class='revenue_count'>${
        revenueData.value?.iknowaPercentage +
          revenueData.value?.directPercentage >
        0
          ? `<img src="${arrowUpIcon}" />`
          : revenueData.value?.iknowaPercentage +
              revenueData.value?.directPercentage <
            0
          ? `<img src="${arrowDownIcon}" />`
          : "" // No image if the percentage is 0
      } ${
        revenueData.value?.iknowaPercentage +
        revenueData.value?.directPercentage
      }% from last month</span></div>`,
      useHTML: true,
      verticalAlign: "middle",
      align: "center",
    },
  };
});

const revenuePerformanceChartOptions = computed(() => {
  return {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: xAxisCategories.value,
    },
    yAxis: {
      visible: true,
      min: 0,
      title: {
        text: "",
      },
      gridLineWidth: 0,
      labels: {
        enabled: false, // Disables the yAxis labels (data points)
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color: "#000",
        },
        formatter: function () {
          return CurrencyFilter.formatToCurrency(this.total.toLocaleString()); // Format total with currency
        },
      },
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br/>",
      pointFormat: "{series.name}: £{point.y}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "iknowa Revenue",
        data: yAxisIknowaRevenuePerformanceData.value,
        color: "#4F55F0", // Blue
      },
      {
        name: "Direct Revenue",
        data: yAxisDirectRevenuePerformanceData.value, // Update with your actual data
        color: "#FFA500", // Orange
      },
    ],
  };
});

const revenuePerformanceChartEmptyStateOptions = computed(() => {
  return {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: xAxisCategories.value,
    },
    yAxis: {
      visible: true,
      min: 0,
      title: {
        text: "",
      },
      gridLineWidth: 0,
      labels: {
        enabled: false, // Disables the yAxis labels (data points)
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color: "#000",
        },
        formatter: function () {
          return CurrencyFilter.formatToCurrency("0"); // Format total with currency
        },
      },
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br/>",
      pointFormat: "{series.name}: £0",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "iknowa Revenue",
        data: [5, 5, 5],
        color: "#5F5F5F", // Blue
      },
      {
        name: "Direct Revenue",
        data: [5, 5, 5], // Update with your actual data
        color: "#AEAEAE", // Orange
      },
    ],
  };
});

const updateRevenuePerformanceChart = () => {
  console.log("updateRevenuePerformanceChart");

  revenuePerformanceChart.value.xAxis[0].setCategories(xAxisCategories.value);
  revenuePerformanceChart.value.series[0].setData(
    yAxisIknowaRevenuePerformanceData.value
  );
  revenuePerformanceChart.value.series[1].setData(
    yAxisDirectRevenuePerformanceData.value
  );
};
// setTimeout(() => {
//   updateRevenuePerformanceChart();
// }, 5000);

// watch(
//   () => revenueData.value,
//   async (newValue) => {
//     if (newValue) {
//       if (hasRevenueData.value) {
//         console.log(hasRevenueData.value, "hasRevenueData watch runnedd");
//         console.log(revenueOverviewChart.value, "revenueOverviewChart.value.");
//         revenueOverviewChart.value.series[0].setData([
//           {
//             name: "Residential",
//             y: revenueData.value?.iknowaRevenueByPropertyCategory.residential,
//             color: "#4F55F0",
//           },
//           {
//             name: "Commercial",
//             y: revenueData.value?.iknowaRevenueByPropertyCategory.commercial,
//             color: "#4F55F0",
//           },
//           {
//             name: "Residential",
//             y: revenueData.value?.directRevenueByPropertyCategory.residential,
//             color: "#FFA500",
//           },
//           {
//             name: "Commercial",
//             y: revenueData.value?.directRevenueByPropertyCategory.commercial,
//             color: "#FFA500",
//           },
//         ]);
//         revenueOverviewChart.value.setSubtitle({
//           text: `<div><p>Total revenue</p><h5>${CurrencyFilter.formatToCurrency(
//             revenueData.value?.totalRevenue.toLocaleString()
//           )}</h5><span class='revenue_count'>${
//             revenueData.value?.iknowaPercentage +
//               revenueData.value?.directPercentage >
//             0
//               ? `<img src="${arrowUpIcon}" />`
//               : revenueData.value?.iknowaPercentage +
//                   revenueData.value?.directPercentage <
//                 0
//               ? `<img src="${arrowDownIcon}" />`
//               : "" // No image if the percentage is 0
//           } ${
//             revenueData.value?.iknowaPercentage +
//             revenueData.value?.directPercentage
//           }% from last month</span></div>`,
//           useHTML: true,
//           verticalAlign: "middle",
//           align: "center",
//         });
//       }
//     }
//   },

//   { immediate: true, deep: true }
// );

const fetchRevenuePerformance = async () => {
  loadingRevenuePerformance.value = true;
  let filter = "last3months"; // default date range

  if (selectedFilter.value) {
    filter = selectedFilter.value;
  }

  const params = {
    filter,
  };
  console.log("params", params);
  try {
    await store.dispatch(`${ANALYTICS_STORE}/fetchRevenuePerformance`, params);
  } catch (error) {
    console.log(error);
  } finally {
    loadingRevenuePerformance.value = false;
  }
};

watch(
  () => selectedFilter.value,
  async (newVal) => {
    if (newVal) {
      await fetchRevenuePerformance();
      await updateRevenuePerformanceChart();
    }
  },
  { immediate: true, deep: true }
);

onBeforeMount(async () => {
  await fetchRevenuePerformance();
});

onMounted(async () => {
  await fetchRevenuePerformance();
  // revenuePerformanceChart.value = Highcharts.chart(
  //   "revenuePerformanceChart",
  //   revenuePerformanceChartOptions.value
  // );
  revenuePerformanceChartEmptyState.value = Highcharts.chart(
    "revenuePerformanceChartEmptyState",
    revenuePerformanceChartEmptyStateOptions.value
  );
  // if (hasRevenueData.value) {
  revenueOverviewChart.value = Highcharts.chart(
    "revenueOverviewChart",
    revenueOverviewChartOption.value
  );
  // } else {
  revenueOverviewChartEmptyState.value = Highcharts.chart(
    "revenueOverviewChartEmptyState",
    revenueOverviewChartOptionEmptyState.value
  );
  // }
});
</script>

<style lang="scss" scoped>
.revenue__widget {
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  @include respond(s1024) {
    flex-direction: column;
  }
  .revenue__card {
    width: calc(50% - 8px);
    max-width: 100%;
    @include respond(s1024) {
      width: 100%;
    }
    .analytics_card__content {
      .card__content {
        width: 100%;
        max-width: 100%;
        .revenue_overview__content {
          width: 100%;
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include respond(s720) {
            flex-direction: column;
            padding: 36px;
          }
          .revenue_left__block {
            width: 50%;
            max-width: 100%;
            @include respond(s720) {
              width: 100%;
            }
            :deep(.highcharts-container) {
              width: 100% !important;
              .highcharts-subtitle {
                text-align: center;
                p {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 500;
                  color: rgba($blueDark, 0.5);
                  letter-spacing: 0.25px;
                }
                h5 {
                  @include fluidFont(24, 20, 1.3);
                  font-weight: 700;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.25px;
                }
                .revenue_count {
                  @include fluidFont(10, 10, 1.3);
                  font-weight: 600;
                  color: rgba($blueDark, 0.5);
                  letter-spacing: 0.25px;
                }
              }
            }
          }
          .revenue_right__block {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            border-left: 1px solid rgba(7, 9, 44, 0.2);
            width: 50%;
            max-width: 100%;

            @include respond(s720) {
              width: 100%;
              border: none;
              flex-direction: row;
              justify-content: center;
            }
            .iknowa__revenue {
              .top__section {
                display: flex;
                gap: 8px;
                align-items: center;
                text-align: left;
                white-space: nowrap;
                .empty-span-iknowa-revenue {
                  width: 12px;
                  height: 12px;
                  border-radius: 100%;
                  background-color: #5f5f5f;
                  box-shadow: 0px 0px 2px 0px #0c0f4a4d;
                  display: inline-block;
                }
                span {
                  width: 12px;
                  height: 12px;
                  border-radius: 100%;
                  background-color: rgba($SecondaryBrightBlue, 1);
                  box-shadow: 0px 0px 2px 0px #0c0f4a4d;
                  display: inline-block;
                }
                p {
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 600;
                  color: rgba($blueDark, 0.5);
                  letter-spacing: 0.25px;
                }
              }
              h5 {
                @include fluidFont(18, 18, 1.2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                margin-top: 8px;
                text-align: left;
                margin-left: 20px;
              }
            }
            .direct__revenue {
              .top__section {
                display: flex;
                gap: 8px;
                align-items: center;
                text-align: left;
                white-space: nowrap;
                .empty-span-direct-revenue {
                  width: 12px;
                  height: 12px;
                  border-radius: 100%;
                  background-color: #aeaeae;
                  box-shadow: 0px 0px 2px 0px #0c0f4a4d;
                  display: inline-block;
                }
                span {
                  width: 12px;
                  height: 12px;
                  border-radius: 100%;
                  background-color: rgba($orange, 1);
                  box-shadow: 0px 0px 2px 0px #0c0f4a4d;
                  display: inline-block;
                }
                p {
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 600;
                  color: rgba($blueDark, 0.5);
                  letter-spacing: 0.25px;
                }
              }
              h5 {
                @include fluidFont(18, 18, 1.2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                margin-top: 8px;
                text-align: left;
                margin-left: 20px;
              }
            }
          }
        }
        .revenue_overview__footer {
          border-top: 1px dashed rgba(134, 135, 165, 0.66);
          width: 100%;
          max-width: 100%;
          display: flex;
          justify-content: center;
          gap: 40px;
          padding-top: 16px;
          .custom__tag {
            display: flex;
            gap: 4px;
            align-items: center;
            .icon {
              width: 18px;
              height: 18px;
              border-radius: 100%;
              background-color: rgba($blueDark, 0.5);
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                filter: invert(100%) sepia(3%) saturate(41%) hue-rotate(184deg)
                  brightness(117%) contrast(100%);
                width: 10px;
              }
            }
            span {
              @include fluidFont(14, 14, 1.3);
              font-weight: 500;
              color: rgba($blueDark, 0.5);
            }
          }
        }
      }
    }
  }
}

:deep(.highcharts-credits) {
  display: none !important;
}
</style>
