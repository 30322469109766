<template>
  <Card
    class="!tw-py-4 !tw-box-border tw-mb-2 !tw-shadow-none tw-max-h-[150px]"
  >
    <template #content>
      <section
        class="small-phase-item tw-flex tw-flex-col tw-h-full tw-w-full tw-gap-3"
      >
        <PhaseTitle
          :title="estimate.name"
          :phaseNumber="phaseIndex + 1"
          :estimate="estimate"
          :textColor="estimate?.color || '#4A92E5'"
        />
        <div
          v-if="!isHistoricalProject"
          class="tw-flex tw-items-center tw-w-full tw-box-border tw-gap-2 tw-flex-wrap"
        >
          <div
            v-for="(summary, index) in state.normalizePhaseSummaryData"
            :key="index"
            class="small-phase-item__item tw-pr-4 tw-flex tw-gap-2 tw-items-start"
          >
            <Text variant="span">{{ summary.data }}</Text>
            <Text
              v-if="summary.label"
              variant="span"
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.5)"
              >{{ summary?.label }}</Text
            >
          </div>
        </div>
        <ContractorItem
          v-if="!isHistoricalProject"
          class="!tw-px-4"
          :contractorDetails="contractorDetails"
        />
      </section>
    </template>
  </Card>
</template>
<script setup lang="ts">
import { computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PhaseTitle from "@/modules/project/components/common/PhaseTitle.vue";
import ContractorItem from "@/core/uni-components/ContractorItem.vue";
import estimateConstants from "@/core/constants/estimate";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import workstation, { WORKSTATION } from "@/store/modules/workstation";

interface phaseSummaryDataType {
  label?: string;
  data: any;
}

const store = useStore();

const props = defineProps(["phaseIndex", "estimate", "isHistoricalProject"]);

const state = reactive({
  normalizePhaseSummaryData: [] as phaseSummaryDataType[],
});

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const contractorDetails = computed(() => {
  return {
    userName: props?.estimate?.user?.firstName,
    workstationName: props?.estimate?.workStation?.name,
    profileImage: props?.estimate?.workStation?.profileImage,
  };
});
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const isLabour = computed(() => {
  return props.estimate?.stageType === "labour";
});

const onInitialize = () => {
  if (props.estimate) {
    let phaseSummaryData: phaseSummaryDataType[];

    if (isLabour.value) {
      phaseSummaryData = [
        {
          label: "tasks",
          data: props.estimate?.projectStageTasks.length,
        },
        {
          label: getDurationType(props.estimate.durationType),
          data: props.estimate?.totalDuration,
        },
        {
          data: CurrencyFilter.formatToCurrency(props.estimate?.subTotal),
        },
        {
          label: "Properties",
          data: getEstimateProperties.value,
        },
      ];
    } else {
      phaseSummaryData = [
        {
          label: "items",
          data: props.estimate?.projectStageTasks.length,
        },
        {
          data: CurrencyFilter.formatToCurrency(props.estimate?.subTotal),
        },
        {
          label: "Properties",
          data: getEstimateProperties.value,
        },
      ];
    }

    state.normalizePhaseSummaryData = phaseSummaryData;
  }
};

const getEstimateProperties = computed(() => {
  const materialProperties =
    props.estimate.estimationPhaseProperties &&
    props.estimate.estimationPhaseProperties.length
      ? props.estimate.estimationPhaseProperties.length
      : 0;

  let labourProperties = 0;
  let labourPropertiesArray = [] as any;

  if (props.estimate.projectStageTasks && isLabour.value) {
    props.estimate.projectStageTasks.forEach((task: any) => {
      if (
        task.estimationPhaseTasksProperties &&
        task.estimationPhaseTasksProperties.length
      ) {
        task.estimationPhaseTasksProperties.forEach((property: any) => {
          if (!labourPropertiesArray.includes(property.propertyId)) {
            labourPropertiesArray.push(property.propertyId);
          }
        });
      }
    });
  }

  labourProperties = labourPropertiesArray.length;

  return isLabour.value ? labourProperties : materialProperties;
});
const getDurationType = (durationType: any) => {
  if (durationType === estimateConstants.DAYS) {
    return "Days";
  }
  if (durationType === estimateConstants.WEEKS) {
    return "Weeks";
  }
  if (durationType === estimateConstants.MONTHS) {
    return "Months";
  }
  return "";
};

watch(
  () => props.estimate,
  (newValue, oldValue) => {
    onInitialize();
  },
  { deep: true, immediate: true }
);

onMounted(async () => {
  await onInitialize();
});
</script>
<script lang="ts">
export default {
  name: "SmallPhaseItem",
};
</script>
<style lang="scss" scoped>
.small-phase-item {
  &__item {
    border-right: 2px solid rgba(12, 15, 74, 0.2);

    &:last-child {
      border-right: unset;
    }
  }
}
:deep(.el-collapse-item__header) {
  @apply tw-border-0;
}
:deep(.el-collapse-item__wrap) {
  @apply tw-border-0;
}
</style>
